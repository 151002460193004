.main__page__header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 1rem 0;
    background-color: @clr-dark--mode;
    box-shadow: 0 4px 28px rgba(@clr-dark--100, 3%);
    height: auto;

    --logo-size: 150px;

    @media screen and (max-width: @max-767) {
        padding: 0.8rem 0;
    }

    .logo__wrapper {
        height: auto;

        @media screen and (max-width: @max-767) {
            width: var(--logo-size);
        }

        img {
            display: block;
        }
    }

    .nav__wrapper {
        @media screen and (max-width: @max-767) {
            width: calc(100% - var(--logo-size));
        }
    }

    .col_etc {
        display: flex;
        align-items: center;
        justify-content: right;
        gap: 1rem;

        .search_wrap {
            position: relative;
            margin: 0;
            width: 100%;
            max-width: 460px;

            @media screen and (max-width: @max-1560) {
                max-width: 360px;
            }

            @media screen and (max-width: @max-1280) {
                max-width: 300px;
            }

            @media screen and (max-width: @max-991) {
                position: fixed;
                top: 60px;
                left: 0;
                max-width: 100%;
                opacity: 0;
                pointer-events: none;
                box-shadow: 0 100vh 0px 100vh hsla(0, 0%, 0%, 0.75);
                transition: opacity 300ms;
                &.open {
                    opacity: 1;
                    pointer-events: all;
                    background: #14141f;
                    border: dotted 1px rgba(255, 255, 255, 0.1);
                    border-left: none;
                    border-right: none;
                    padding: 0 1rem;
                    svg {
                        left: 2rem;
                    }
                }
            }


            svg {
                position: absolute;
                font-size: 1.125rem;
                top: 1.4rem;
                left: 1.4rem;
                color: @clr-primary--500;
            }

            .input {
                border-radius: 50px;
                padding-left: 3em;
                width: 100%;
                font-size: 1.125rem;
                font-weight: 500;
                padding: 0.8em 1em 0.8em 2.8em;
                outline: none;
                resize: none;
                background-color: rgba(@clr-dark--100, 5%);
                border: none;
                color: @input-clr;
                transition: box-shadow 400ms ease-in-out;
                height: 51px;

                @media screen and (max-width: @max-1560) {
                    height: 42px;
                }
            }
        }

        .btn_wrap {
            display: flex;
            align-items: center;
            gap: 1rem;

            .btn {
                margin: 0;
                line-height: normal;
                min-height: 52px;
                min-width: 210px;
                b {
                    font-weight: 600;
                }

                .iconFont-add {
                    font-size: 1.25em;
                    color: @clr-primary--400;
                    transition: color 400ms;
                }

                &:hover,
                &:focus {
                    .iconFont-add {
                        color: white;
                    }
                }
            }
            .linkDropdown{
                display: flex;
                flex-wrap: wrap;
                .btn{
                    border-radius: 2em 0 0 2em;
                }
                .UserDropDown{
                    a{
                    display: flex;
                    flex-wrap: wrap;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    background: @clr-primary--500;
                    border-radius: 0 2rem 2rem 0;
                    padding: 0 20px;
                    border-left: 1px solid rgba(255,255,255,0.3);
                    .ant-space-item{
                        display: flex;
                        .anticon{
                            svg{
                                color: #fff;
                            }
                        }
                    }
                    }
                }
                @media screen and (max-width: @max-991) {
                .UserDropDown{
                    a{
                        padding: 0 12px;
                    }
                }
            }                                                                                                                                                                                                                                                                                                                                                 
            }
            @media screen and (max-width: @max-991) {
                .btn{
                    min-width: auto;
                    min-height: 38px;
                        b{
                            display: none;
                        }
                   
                }
                .linkDropdown{
                    a{
                        min-width: auto;
                        b{
                            display: none;
                        }
                    }
                }
            }
        }

        .btn_themeMode {
            margin: 0;
            padding: 0;
            width: 3.125rem;
            height: 3.125rem;
            display: flex;
            font-size: 1.5rem;
            align-items: center;
            justify-content: center;
            margin-left: 5%;

            @media screen and (max-width: @max-1560) {
                margin-left: 1rem;

                svg {
                    max-width: 25px;
                }
            }

            @media screen and (max-width: @max-767) {
                margin: 0;
            }
        }

        .btn_search {
            display: none;

            @media screen and (max-width: @max-991) {
                display: flex;
                font-size: 1.2rem;
            }
        }
        
        @media screen and (max-width: @max-767) {
            .btn {
                padding: 0;
                border-radius: 50px;
                width: 38px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0;

                b {
                    display: none;
                }

                svg,
                img {
                    max-width: 20px;
                }
            }
        }
    }
}