/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx8fFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSx4fFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDlR4fFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4fFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqD-R4fFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDFRkfFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDLBkfFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSxkfFg.ttf) format('truetype');
}
@font-face {
  font-family: 'Urbanist';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDYhkfFg.ttf) format('truetype');
}
@font-face {
  font-display: auto;
  font-family: "BlockChainIcons";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/iconly.eot?1688643768299");
  src: url("../fonts/iconly.eot?#iefix") format("embedded-opentype"), url("../fonts/iconly.woff2?1688643768299") format("woff2"), url("../fonts/iconly.woff?1688643768299") format("woff"), url("../fonts/iconly.ttf?1688643768299") format("truetype"), url("../fonts/iconly.svg?1688643768299#BlockChainIcons") format("svg");
}
[class="iconFont"],
[class^="iconFont-"],
[class*=" iconFont-"] {
  display: inline-block;
  font-family: "BlockChainIcons" !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.iconFont-youtube:before {
  content: "\e000";
}
.iconFont-wallet:before {
  content: "\e001";
}
.iconFont-twitter:before {
  content: "\e002";
}
.iconFont-search:before {
  content: "\e003";
}
.iconFont-reddit:before {
  content: "\e004";
}
.iconFont-plus:before {
  content: "\e005";
}
.iconFont-minus:before {
  content: "\e006";
}
.iconFont-list:before {
  content: "\e007";
}
.iconFont-light:before {
  content: "\e008";
}
.iconFont-instagram:before {
  content: "\e009";
}
.iconFont-facebook:before {
  content: "\e00a";
}
.iconFont-discord:before {
  content: "\e00b";
}
.iconFont-close:before {
  content: "\e00c";
}
.iconFont-cart:before {
  content: "\e00d";
}
.iconFont-box:before {
  content: "\e00e";
}
.iconFont-arrow-right:before {
  content: "\e00f";
}
.iconFont-arrow-left:before {
  content: "\e010";
}
.iconFont-add:before {
  content: "\e011";
}
.iconFont-cart-line:before {
  content: "\e012";
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
/* Create Width Hadling Classes */
.span-100 {
  width: 100%;
}
.span-95 {
  width: 95%;
}
.span-90 {
  width: 90%;
}
.span-85 {
  width: 85%;
}
.span-80 {
  width: 80%;
}
.span-75 {
  width: 75%;
}
.span-70 {
  width: 70%;
}
.span-65 {
  width: 65%;
}
.span-60 {
  width: 60%;
}
.span-55 {
  width: 55%;
}
.span-50 {
  width: 50%;
}
.span-45 {
  width: 45%;
}
.span-40 {
  width: 40%;
}
.span-35 {
  width: 35%;
}
.span-30 {
  width: 30%;
}
.span-25 {
  width: 25%;
}
.span-20 {
  width: 20%;
}
.span-15 {
  width: 15%;
}
.span-10 {
  width: 10%;
}
.span-5 {
  width: 5%;
}
.width-1000 {
  width: 1000px;
  max-width: 100%;
}
.width-900 {
  width: 900px;
  max-width: 100%;
}
.width-800 {
  width: 800px;
  max-width: 100%;
}
.width-700 {
  width: 700px;
  max-width: 100%;
}
.width-600 {
  width: 600px;
  max-width: 100%;
}
.width-500 {
  width: 500px;
  max-width: 100%;
}
.width-475 {
  width: 475px;
  max-width: 100%;
}
.width-450 {
  width: 450px;
  max-width: 100%;
}
.width-425 {
  width: 425px;
  max-width: 100%;
}
.width-400 {
  width: 400px;
  max-width: 100%;
}
.width-375 {
  width: 375px;
  max-width: 100%;
}
.width-350 {
  width: 350px;
  max-width: 100%;
}
.width-325 {
  width: 325px;
  max-width: 100%;
}
.width-300 {
  width: 300px;
  max-width: 100%;
}
.width-275 {
  width: 275px;
  max-width: 100%;
}
.width-250 {
  width: 250px;
  max-width: 100%;
}
.width-225 {
  width: 225px;
  max-width: 100%;
}
.width-200 {
  width: 200px;
  max-width: 100%;
}
.width-175 {
  width: 175px;
  max-width: 100%;
}
.width-150 {
  width: 150px;
  max-width: 100%;
}
.width-125 {
  width: 125px;
  max-width: 100%;
}
.width-100 {
  width: 100px;
  max-width: 100%;
}
.width-75 {
  width: 75px;
  max-width: 100%;
}
.width-50 {
  width: 50px;
  max-width: 100%;
}
.width-25 {
  width: 25px;
  max-width: 100%;
}
/* Create Margin and Padding Handling Classes */
/* Margin */
.m-100 {
  margin: 100px;
}
.ml-100 {
  margin-left: 100px;
}
.mr-100 {
  margin-right: 100px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mh-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}
/* Padding */
.p-100 {
  padding: 100px;
}
.pl-100 {
  padding-left: 100px;
}
.pr-100 {
  padding-right: 100px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.ph-100 {
  padding-left: 100px;
  padding-right: 100px;
}
.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
/* Margin */
.m-95 {
  margin: 95px;
}
.ml-95 {
  margin-left: 95px;
}
.mr-95 {
  margin-right: 95px;
}
.mt-95 {
  margin-top: 95px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mh-95 {
  margin-left: 95px;
  margin-right: 95px;
}
.mv-95 {
  margin-top: 95px;
  margin-bottom: 95px;
}
/* Padding */
.p-95 {
  padding: 95px;
}
.pl-95 {
  padding-left: 95px;
}
.pr-95 {
  padding-right: 95px;
}
.pt-95 {
  padding-top: 95px;
}
.pb-95 {
  padding-bottom: 95px;
}
.ph-95 {
  padding-left: 95px;
  padding-right: 95px;
}
.pv-95 {
  padding-top: 95px;
  padding-bottom: 95px;
}
/* Margin */
.m-90 {
  margin: 90px;
}
.ml-90 {
  margin-left: 90px;
}
.mr-90 {
  margin-right: 90px;
}
.mt-90 {
  margin-top: 90px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mh-90 {
  margin-left: 90px;
  margin-right: 90px;
}
.mv-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}
/* Padding */
.p-90 {
  padding: 90px;
}
.pl-90 {
  padding-left: 90px;
}
.pr-90 {
  padding-right: 90px;
}
.pt-90 {
  padding-top: 90px;
}
.pb-90 {
  padding-bottom: 90px;
}
.ph-90 {
  padding-left: 90px;
  padding-right: 90px;
}
.pv-90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
/* Margin */
.m-85 {
  margin: 85px;
}
.ml-85 {
  margin-left: 85px;
}
.mr-85 {
  margin-right: 85px;
}
.mt-85 {
  margin-top: 85px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mh-85 {
  margin-left: 85px;
  margin-right: 85px;
}
.mv-85 {
  margin-top: 85px;
  margin-bottom: 85px;
}
/* Padding */
.p-85 {
  padding: 85px;
}
.pl-85 {
  padding-left: 85px;
}
.pr-85 {
  padding-right: 85px;
}
.pt-85 {
  padding-top: 85px;
}
.pb-85 {
  padding-bottom: 85px;
}
.ph-85 {
  padding-left: 85px;
  padding-right: 85px;
}
.pv-85 {
  padding-top: 85px;
  padding-bottom: 85px;
}
/* Margin */
.m-80 {
  margin: 80px;
}
.ml-80 {
  margin-left: 80px;
}
.mr-80 {
  margin-right: 80px;
}
.mt-80 {
  margin-top: 80px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mh-80 {
  margin-left: 80px;
  margin-right: 80px;
}
.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}
/* Padding */
.p-80 {
  padding: 80px;
}
.pl-80 {
  padding-left: 80px;
}
.pr-80 {
  padding-right: 80px;
}
.pt-80 {
  padding-top: 80px;
}
.pb-80 {
  padding-bottom: 80px;
}
.ph-80 {
  padding-left: 80px;
  padding-right: 80px;
}
.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
/* Margin */
.m-75 {
  margin: 75px;
}
.ml-75 {
  margin-left: 75px;
}
.mr-75 {
  margin-right: 75px;
}
.mt-75 {
  margin-top: 75px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mh-75 {
  margin-left: 75px;
  margin-right: 75px;
}
.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px;
}
/* Padding */
.p-75 {
  padding: 75px;
}
.pl-75 {
  padding-left: 75px;
}
.pr-75 {
  padding-right: 75px;
}
.pt-75 {
  padding-top: 75px;
}
.pb-75 {
  padding-bottom: 75px;
}
.ph-75 {
  padding-left: 75px;
  padding-right: 75px;
}
.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}
/* Margin */
.m-70 {
  margin: 70px;
}
.ml-70 {
  margin-left: 70px;
}
.mr-70 {
  margin-right: 70px;
}
.mt-70 {
  margin-top: 70px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mh-70 {
  margin-left: 70px;
  margin-right: 70px;
}
.mv-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}
/* Padding */
.p-70 {
  padding: 70px;
}
.pl-70 {
  padding-left: 70px;
}
.pr-70 {
  padding-right: 70px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-70 {
  padding-bottom: 70px;
}
.ph-70 {
  padding-left: 70px;
  padding-right: 70px;
}
.pv-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
/* Margin */
.m-65 {
  margin: 65px;
}
.ml-65 {
  margin-left: 65px;
}
.mr-65 {
  margin-right: 65px;
}
.mt-65 {
  margin-top: 65px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mh-65 {
  margin-left: 65px;
  margin-right: 65px;
}
.mv-65 {
  margin-top: 65px;
  margin-bottom: 65px;
}
/* Padding */
.p-65 {
  padding: 65px;
}
.pl-65 {
  padding-left: 65px;
}
.pr-65 {
  padding-right: 65px;
}
.pt-65 {
  padding-top: 65px;
}
.pb-65 {
  padding-bottom: 65px;
}
.ph-65 {
  padding-left: 65px;
  padding-right: 65px;
}
.pv-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}
/* Margin */
.m-60 {
  margin: 60px;
}
.ml-60 {
  margin-left: 60px;
}
.mr-60 {
  margin-right: 60px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mh-60 {
  margin-left: 60px;
  margin-right: 60px;
}
.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}
/* Padding */
.p-60 {
  padding: 60px;
}
.pl-60 {
  padding-left: 60px;
}
.pr-60 {
  padding-right: 60px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.ph-60 {
  padding-left: 60px;
  padding-right: 60px;
}
.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
/* Margin */
.m-55 {
  margin: 55px;
}
.ml-55 {
  margin-left: 55px;
}
.mr-55 {
  margin-right: 55px;
}
.mt-55 {
  margin-top: 55px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mh-55 {
  margin-left: 55px;
  margin-right: 55px;
}
.mv-55 {
  margin-top: 55px;
  margin-bottom: 55px;
}
/* Padding */
.p-55 {
  padding: 55px;
}
.pl-55 {
  padding-left: 55px;
}
.pr-55 {
  padding-right: 55px;
}
.pt-55 {
  padding-top: 55px;
}
.pb-55 {
  padding-bottom: 55px;
}
.ph-55 {
  padding-left: 55px;
  padding-right: 55px;
}
.pv-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}
/* Margin */
.m-50 {
  margin: 50px;
}
.ml-50 {
  margin-left: 50px;
}
.mr-50 {
  margin-right: 50px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mh-50 {
  margin-left: 50px;
  margin-right: 50px;
}
.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}
/* Padding */
.p-50 {
  padding: 50px;
}
.pl-50 {
  padding-left: 50px;
}
.pr-50 {
  padding-right: 50px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-50 {
  padding-bottom: 50px;
}
.ph-50 {
  padding-left: 50px;
  padding-right: 50px;
}
.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
/* Margin */
.m-45 {
  margin: 45px;
}
.ml-45 {
  margin-left: 45px;
}
.mr-45 {
  margin-right: 45px;
}
.mt-45 {
  margin-top: 45px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mh-45 {
  margin-left: 45px;
  margin-right: 45px;
}
.mv-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}
/* Padding */
.p-45 {
  padding: 45px;
}
.pl-45 {
  padding-left: 45px;
}
.pr-45 {
  padding-right: 45px;
}
.pt-45 {
  padding-top: 45px;
}
.pb-45 {
  padding-bottom: 45px;
}
.ph-45 {
  padding-left: 45px;
  padding-right: 45px;
}
.pv-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
/* Margin */
.m-40 {
  margin: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mh-40 {
  margin-left: 40px;
  margin-right: 40px;
}
.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
/* Padding */
.p-40 {
  padding: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.ph-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
/* Margin */
.m-35 {
  margin: 35px;
}
.ml-35 {
  margin-left: 35px;
}
.mr-35 {
  margin-right: 35px;
}
.mt-35 {
  margin-top: 35px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mh-35 {
  margin-left: 35px;
  margin-right: 35px;
}
.mv-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}
/* Padding */
.p-35 {
  padding: 35px;
}
.pl-35 {
  padding-left: 35px;
}
.pr-35 {
  padding-right: 35px;
}
.pt-35 {
  padding-top: 35px;
}
.pb-35 {
  padding-bottom: 35px;
}
.ph-35 {
  padding-left: 35px;
  padding-right: 35px;
}
.pv-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
/* Margin */
.m-30 {
  margin: 30px;
}
.ml-30 {
  margin-left: 30px;
}
.mr-30 {
  margin-right: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mh-30 {
  margin-left: 30px;
  margin-right: 30px;
}
.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
/* Padding */
.p-30 {
  padding: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.ph-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
/* Margin */
.m-25 {
  margin: 25px;
}
.ml-25 {
  margin-left: 25px;
}
.mr-25 {
  margin-right: 25px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mh-25 {
  margin-left: 25px;
  margin-right: 25px;
}
.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}
/* Padding */
.p-25 {
  padding: 25px;
}
.pl-25 {
  padding-left: 25px;
}
.pr-25 {
  padding-right: 25px;
}
.pt-25 {
  padding-top: 25px;
}
.pb-25 {
  padding-bottom: 25px;
}
.ph-25 {
  padding-left: 25px;
  padding-right: 25px;
}
.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
/* Margin */
.m-20 {
  margin: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mh-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* Padding */
.p-20 {
  padding: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.ph-20 {
  padding-left: 20px;
  padding-right: 20px;
}
.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
/* Margin */
.m-15 {
  margin: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mh-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
/* Padding */
.p-15 {
  padding: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}
.ph-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
/* Margin */
.m-10 {
  margin: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mh-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Padding */
.p-10 {
  padding: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}
.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
/* Margin */
.m-5 {
  margin: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mh-5 {
  margin-left: 5px;
  margin-right: 5px;
}
.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Padding */
.p-5 {
  padding: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}
.ph-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
/* Margin */
.m-0 {
  margin: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mh-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
/* Padding */
.p-0 {
  padding: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.ph-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.m-auto {
  margin-left: auto;
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.m-gutter {
  margin: 24px;
}
.ml-gutter {
  margin-left: 24px;
}
.mr-gutter {
  margin-right: 24px;
}
.mt-gutter {
  margin-top: 24px;
}
.mb-gutter {
  margin-bottom: 24px;
}
.mh-gutter {
  margin-left: 24px;
  margin-right: 24px;
}
.mv-gutter {
  margin-top: 24px;
  margin-bottom: 24px;
}
.p-gutter {
  padding: 24px;
}
.pl-gutter {
  padding-left: 24px;
}
.pr-gutter {
  padding-right: 24px;
}
.pt-gutter {
  padding-top: 24px;
}
.pb-gutter {
  padding-bottom: 24px;
}
.ph-gutter {
  padding-left: 24px;
  padding-right: 24px;
}
.pv-gutter {
  padding-top: 24px;
  padding-bottom: 24px;
}
* {
  box-sizing: border-box;
  word-break: break-word;
}
*::after,
*::before {
  box-sizing: border-box;
}
*::selection {
  background: #C847FF;
  color: #ffffff;
}
html {
  padding: 0px;
  margin: 0px;
  color-scheme: dark;
  scroll-behavior: auto;
  font-size: 16px;
}
@media screen and (max-width: 1560px) {
  html {
    font-size: 14px;
  }
}
html body {
  font-family: 'Urbanist', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
  background-color: #14141F;
  margin: 0px;
  padding: 0px;
  width: 100%;
  min-width: 320px;
  overflow-x: hidden;
  touch-action: manipulation;
}
html body.scroll_off {
  overflow: hidden !important;
}
html body h1,
html body h2,
html body h3,
html body h4,
html body h5,
html body h6 {
  color: #ffffff;
}
html body a {
  display: inline-block;
  color: #C847FF;
  transition: color 500ms;
}
html body a:hover,
html body a:focus {
  color: #3B2A8E;
}
.sc_title {
  margin-bottom: 1.75rem;
}
.sc_title h2,
.sc_title h3 {
  font-size: 2.375rem;
  font-weight: 600;
  color: #C847FF;
  margin-bottom: 0.2em;
}
@media screen and (max-width: 1560px) {
  .sc_title h2,
  .sc_title h3 {
    font-size: 2.25rem;
  }
}
@media screen and (max-width: 1279px) {
  .sc_title h2,
  .sc_title h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .sc_title h2,
  .sc_title h3 {
    font-size: 1.75rem;
  }
}
.sc_title > p {
  margin-bottom: 1.125rem;
}
.main__page__wrapper.has__appsidebar .main__page__content {
  display: flex;
  flex-direction: column;
  background-color: #14141F;
  height: 100vh;
}
.main__page__wrapper .main__app__content {
  display: flex;
  flex-direction: column;
}
.main__page__wrapper .main__app__content .app__container {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}
.main__page__wrapper .main__app__content .app__container__card {
  height: 100%;
}
.ag-grid-wrapper {
  height: 100%;
  min-height: 400px;
}
.logo__wrapper {
  height: 42px;
  display: flex;
  align-items: center;
}
.logo__wrapper img {
  max-height: 60px;
  max-width: 100%;
}
.logo__wrapper img + span {
  margin-left: 10px;
}
.logo__wrapper span {
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  color: #494949;
}
.fullscreen__spinner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.section {
  padding: 50px;
}
.section__big__title {
  display: block;
  font-weight: bold;
  font-size: 56px;
  color: #494949;
}
.section__subtitle {
  display: block;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-card .card__title {
  font-weight: 600;
}
.ant-btn,
.btn {
  font-family: 'Urbanist', sans-serif;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  line-height: normal;
  height: auto;
  column-gap: 0.5em;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0.7em 1.75em;
  border-radius: 2em;
  color: #ffffff;
  background-color: #343444;
  transition: color 500ms, background-color 500ms, border-color 500ms, transform 500ms, box-shadow 500ms, background-image 500ms, background-position 500ms, background-size 500ms;
  transition-timing-function: ease-in-out;
}
@media screen and (max-width: 1560px) {
  .ant-btn,
  .btn {
    padding: 0.85em 1.4em;
  }
}
@media screen and (max-width: 767px) {
  .ant-btn,
  .btn {
    padding: 0.75em 1.25em;
  }
}
.ant-btn [class*="iconForn-"],
.btn [class*="iconForn-"] {
  font-size: 1.25em;
}
.ant-btn:not(:only-child),
.btn:not(:only-child) {
  margin-bottom: 0.25em;
  margin-right: 0.25em;
}
.ant-btn:active,
.btn:active {
  outline: none;
  opacity: 0.925;
}
.ant-btn:hover,
.btn:hover,
.ant-btn:focus,
.btn:focus {
  background-color: #3B2A8E;
}
.ant-btn[disabled],
.btn[disabled],
.ant-btn.disabled,
.btn.disabled {
  filter: opacity(0.5);
  cursor: not-allowed;
  pointer-events: none;
}
.ant-btn.icon-right span,
.btn.icon-right span {
  order: 1;
}
.ant-btn.icon-right [class*="iconFont-"],
.btn.icon-right [class*="iconFont-"] {
  order: 2;
}
.ant-btn.ant-btn-primary,
.btn.ant-btn-primary,
.ant-btn.btn_primary,
.btn.btn_primary {
  color: #ffffff;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
}
.ant-btn.ant-btn-primary:hover,
.btn.ant-btn-primary:hover,
.ant-btn.btn_primary:hover,
.btn.btn_primary:hover,
.ant-btn.ant-btn-primary:focus,
.btn.ant-btn-primary:focus,
.ant-btn.btn_primary:focus,
.btn.btn_primary:focus {
  background-position: right center;
}
.ant-btn.btn_primary_br,
.btn.btn_primary_br {
  color: #ffffff;
  background-image: linear-gradient(to left, #6C76C7 0%, #C61D9C 40%, transparent 60%);
  background-color: transparent;
  background-size: 240% auto;
  box-shadow: inset 0 0 0 1px #c847ff;
  transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.ant-btn.btn_primary_br.active,
.btn.btn_primary_br.active,
.ant-btn.btn_primary_br:hover,
.btn.btn_primary_br:hover,
.ant-btn.btn_primary_br:focus,
.btn.btn_primary_br:focus {
  color: #ffffff;
  background-position: right center;
}
.ant-btn.ant-btn-default,
.btn.ant-btn-default,
.ant-btn.btn_primary_br_White,
.btn.btn_primary_br_White {
  color: #ffffff;
  background-image: linear-gradient(to left, #6C76C7 0%, #C61D9C 40%, transparent 60%);
  background-color: transparent;
  background-size: 240% auto;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 200ms 100ms;
}
.ant-btn.ant-btn-default.active,
.btn.ant-btn-default.active,
.ant-btn.btn_primary_br_White.active,
.btn.btn_primary_br_White.active,
.ant-btn.ant-btn-default:hover,
.btn.ant-btn-default:hover,
.ant-btn.btn_primary_br_White:hover,
.btn.btn_primary_br_White:hover,
.ant-btn.ant-btn-default:focus,
.btn.ant-btn-default:focus,
.ant-btn.btn_primary_br_White:focus,
.btn.btn_primary_br_White:focus {
  color: #ffffff;
  background-position: right center;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0);
  transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 400ms 300ms;
}
.ant-btn.btn_secondary,
.btn.btn_secondary {
  background-color: #3B2A8E;
  color: #ffffff;
}
.ant-btn.btn_secondary:hover,
.btn.btn_secondary:hover,
.ant-btn.btn_secondary:focus,
.btn.btn_secondary:focus {
  background-color: #2b1e67;
}
.ant-btn.btn_none,
.btn.btn_none {
  cursor: unset;
}
.ant-btn.btn_none:hover,
.btn.btn_none:hover,
.ant-btn.btn_none:focus,
.btn.btn_none:focus {
  background-position: inherit;
}
.ant-btn.btn_arrow,
.btn.btn_arrow {
  border-radius: 50px;
  padding: 5px;
  width: 40px;
  height: 40px;
  font-size: 140%;
  background-color: #efefef;
  border: solid 1px #c8c8c8;
  color: #424242;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  transition: border-color 400ms, background-color 400ms;
}
.ant-btn.btn_arrow.left [class*="ico"],
.btn.btn_arrow.left [class*="ico"] {
  transform: scaleX(-1);
}
.ant-btn.btn_arrow span,
.btn.btn_arrow span {
  line-height: 0;
}
.ant-btn.btn_arrow:hover,
.btn.btn_arrow:hover,
.ant-btn.btn_arrow:focus,
.btn.btn_arrow:focus {
  background-color: #3B2A8E;
  border-color: #3B2A8E;
  color: #ffffff;
}
.bg_img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.light_circle {
  position: relative;
  isolation: isolate;
  overflow: hidden;
}
.light_circle .cr_wrap {
  --size: 300px;
  display: block;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  filter: blur(calc(var(--size) / 2));
  animation: crBlur 8s linear infinite;
}
@media screen and (max-width: 1279px) {
  .light_circle .cr_wrap {
    --size: 250px;
  }
}
@media screen and (max-width: 991px) {
  .light_circle .cr_wrap {
    --size: 200px;
  }
}
.light_circle .cr_wrap.cr_primary {
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
}
.light_circle .cr_wrap.cr_secondary {
  --size: 250px;
  left: unset;
  right: 0;
  background-image: none;
  background-color: #3B2A8E;
}
@keyframes crBlur {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.8;
  }
}
.bg_blackness {
  position: relative;
  isolation: isolate;
}
.bg_blackness::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--blackness-clr, #000);
  opacity: var(--blackness, 0.5);
  pointer-events: none;
  z-index: -1;
}
.ant-space-item .ant-select {
  color: white;
}
.ant-space-item .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.25);
  border-radius: 50px;
  font-size: 1.125rem;
  color: white !important;
  height: auto;
  padding: 0.4em 1.5em;
  padding-left: 1.5em;
}
.ant-space-item .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #C847FF;
}
.ant-space-item .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-space-item .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-end: 12px;
}
.ant-space-item .ant-select-single.ant-select-open .ant-select-selection-item {
  color: white;
}
.ant-space-item .ant-select .ant-select-arrow {
  color: rgba(255, 255, 255, 0.75);
  right: 1.5em;
}
.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 0 0;
  padding: 0;
  gap: 0.5rem;
}
.ant-pagination.ant-table-pagination li {
  min-width: 40px;
  height: 40px;
  padding: 0.35em;
  border-radius: 6px;
  margin: 0;
  border: none;
}
.ant-pagination.ant-table-pagination li a {
  color: white;
}
.ant-pagination.ant-table-pagination li.ant-pagination-prev,
.ant-pagination.ant-table-pagination li.ant-pagination-next {
  padding: 0.35em;
  margin: 0;
}
.ant-pagination.ant-table-pagination li.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.ant-table-pagination li.ant-pagination-next .ant-pagination-item-link {
  color: white;
  background: transparent;
  border: none;
  height: auto;
}
.ant-pagination li {
  list-style: none;
  min-width: 45px;
  height: 45px;
  padding: 0.5em;
  background: transparent;
  border-radius: 2em;
}
@media screen and (max-width: 767px) {
  .ant-pagination li {
    min-width: 36px;
    height: 36px;
    padding: 0.25em;
    margin-right: 0.3em;
  }
}
.ant-pagination li.ant-pagination-prev,
.ant-pagination li.ant-pagination-next {
  padding: 0.5em 2em;
}
@media screen and (max-width: 767px) {
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next {
    font-size: 0;
    border-radius: 50px;
  }
  .ant-pagination li.ant-pagination-prev a,
  .ant-pagination li.ant-pagination-next a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .ant-pagination li.ant-pagination-prev svg,
  .ant-pagination li.ant-pagination-next svg {
    font-size: 1rem;
  }
}
.ant-pagination li:not(.ant-pagination-prev),
.ant-pagination li:not(.ant-pagination-next) {
  background-image: none;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  transition: background-color 500ms;
}
.ant-pagination li:not(.ant-pagination-prev):hover,
.ant-pagination li:not(.ant-pagination-next):hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.ant-pagination li.ant-pagination-item-active {
  border: none;
  background-image: linear-gradient(to left, #6C76C7 0%, #C61D9C 40%, transparent 60%);
  color: #ffffff;
  background-position: right center;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0);
  transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 400ms 300ms;
  background-color: transparent;
  background-size: 240% auto;
}
.ant-pagination li a {
  color: #ffffff;
}
.ant-pagination li a:hover,
.ant-pagination li a:focus {
  color: #ffffff;
}
.ant-pagination li .ant-pagination-item-link-icon {
  color: white !important;
  opacity: 1 !important;
}
.ant-pagination li.ant-pagination-options {
  display: none;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-slider .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-slider .slick-list:focus {
  outline: none;
}
.slick-slider .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-slider .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-slider .slick-track:before,
.slick-slider .slick-track:after {
  display: table;
  content: "";
}
.slick-slider .slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.input_group {
  margin-bottom: 1.25rem;
}
@media (max-width: 767px) {
  .input_group {
    margin-bottom: 1rem;
  }
}
.input_group.mb-0 {
  margin-bottom: 0;
}
.input_group label {
  display: block;
  font-size: 1.125rem;
  font-weight: 500;
  height: auto;
  color: #ffffff;
}
.input_group .ant-input,
.input_group .input {
  width: 100%;
  height: auto;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.8em 1em !important;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: none;
  color: #FF31CA;
  transition: box-shadow 400ms ease-in-out;
}
.input_group .ant-input:focus,
.input_group .input:focus {
  box-shadow: 0 0 0px 2px rgba(200, 71, 255, 0.75);
}
.input_group .ant-form-item-optional {
  color: rgba(255, 255, 255, 0.2) !important;
  opacity: 0 !important;
}
.input_group .input_select .ant-select-selector {
  width: 100%;
  height: auto;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.8em 1em !important;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: none;
  transition: box-shadow 400ms ease-in-out;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 0.75em);
  background-size: 0.75em;
  color: #FF31CA;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: calc(1em + 15px);
}
.input_group .input_select .ant-select-selector:focus {
  box-shadow: 0 0 0px 2px rgba(200, 71, 255, 0.75);
}
.input_group .ant-picker {
  width: 100%;
  height: auto;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.8em 1em;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  color: #FF31CA;
  transition: box-shadow 400ms ease-in-out;
}
.ant-form-item-control .ant-form-item-control-input .ant-input-affix-wrapper {
  padding: 0;
  background-color: transparent;
  border: none !important;
  outline: none;
}
.ant-form-item-control .ant-form-item-control-input .ant-input-affix-wrapper > input {
  padding-right: 80px !important;
}
.ant-form-item-control .ant-form-item-control-input .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  z-index: 9;
  color: #fff;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 17px;
  color: #FF31CA;
  font-weight: 700;
  padding-left: 20px;
}
.ant-form-item-control .ant-form-item-control-input .ant-input-affix-wrapper .ant-input-suffix::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 36px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.25);
}
.ant-select-dropdown {
  background-color: #191921;
  color: white;
}
.ant-select-dropdown .ant-select-item {
  color: white;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #252530;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #20202a;
}
.ant-skeleton-element,
.ant-skeleton-element .ant-skeleton-image.detail_skeleton {
  width: 100%;
  height: 100%;
  line-height: normal;
}
.ant-skeleton-element .ant-skeleton-image-svg,
.ant-skeleton-element .ant-skeleton-image.detail_skeleton .ant-skeleton-image-svg {
  width: 50%;
  height: 50%;
  line-height: normal;
}
.ant-radio-wrapper {
  margin: 0;
}
.ant-radio-wrapper .ant-radio .ant-radio-inner {
  background-color: transparent;
  border: 2px solid #fff;
  width: 30px;
  height: 30px;
}
.ant-radio-wrapper .ant-radio .ant-radio-inner::after {
  width: 12px;
  height: 12px;
  background-color: #FF31CA;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
}
.bid_containet {
  padding: 40px;
}
.bid_containet .main-map {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 14px 35px;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .bid_containet {
    padding: 30px;
  }
  .bid_containet .main-map {
    padding: 14px 20px;
  }
}
.ant-modal-footer {
  border-top: 0;
}
.dropDownUser .ant-dropdown-menu {
  border-radius: 20px;
  background: #14141F;
  box-shadow: 0px 4px 39px 0px rgba(255, 255, 255, 0.29);
  padding: 15px 25px;
  position: relative;
}
.dropDownUser .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
  background-color: transparent;
}
.dropDownUser .ant-dropdown-menu .ant-dropdown-menu-item a {
  padding: 15px;
  color: #D9D9D9;
  font-size: 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
}
.dropDownUser .ant-dropdown-menu .ant-dropdown-menu-item:last-child a {
  border-bottom: none;
}
.dropDownUser .ant-dropdown-menu .ant-dropdown-menu-item:hover a {
  color: #C61D9C;
}
.dropDownUser .ant-dropdown-menu::before {
  content: "";
  position: absolute;
  right: 30px;
  top: -25px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 32px 16px;
  border-color: transparent transparent #14141F transparent;
  display: none;
}
@media (max-width: 767px) {
  .dropDownUser .ant-dropdown-menu .ant-dropdown-menu-item a {
    font-size: 16px;
    padding: 10px;
  }
  .dropDownUser .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-item-icon {
    width: 22px;
    margin-right: 5px;
  }
}
.auctions {
  position: fixed;
  bottom: 80px;
  left: 20px;
}
.auctions button {
  width: 70px;
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.ant-popover {
  width: 480px;
}
.ant-popover .ant-popover-inner {
  border-radius: 10px;
}
.ant-popover .ant-popover-inner .ant-popover-inner-content {
  width: 100%;
  padding: 10px;
}
.auctionItemWrap {
  margin: 0;
  padding: 10px;
  max-height: 400px;
  overflow-X: hidden;
  overflow-Y: auto;
}
.auctionItemWrap::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
@media screen and (max-width: 767px) {
  .auctionItemWrap::-webkit-scrollbar {
    width: 3px;
  }
}
.auctionItemWrap::-webkit-scrollbar-track {
  background-color: #e6e6e6;
  border-radius: 4px;
}
.auctionItemWrap::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 4px;
}
.auctionItemWrap .auctionItem {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(114, 114, 114, 0.5);
}
.auctionItemWrap .auctionItem:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.auctionItemWrap .auctionItem .thumb img {
  max-width: 100%;
  border-radius: 15px;
}
.auctionItemWrap .auctionItem h2 {
  font-size: 24px;
  color: #727272;
  margin: 0;
  font-weight: 700;
  line-height: 1;
}
.auctionItemWrap .auctionItem h5 {
  font-size: 18px;
  font-weight: 600;
  color: #FF31CA;
  margin: 0;
  padding: 0;
}
.auctionItemWrap .auctionItem .btnWrap .btn {
  font-size: 12px;
  color: #fff;
  padding: 12px 20px;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
}
.auctionItemWrap .auctionItem .btnWrap .btn:hover,
.auctionItemWrap .auctionItem .btnWrap .btn:focus {
  background-position: right center;
}
.auctionItemWrap .auctionItem .countdownWrap .title {
  font-size: 12px;
  color: #727272;
  font-weight: 700;
  margin-bottom: 5px;
}
.auctionItemWrap .auctionItem .countdownWrap .timer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;
}
.auctionItemWrap .auctionItem .countdownWrap .timer-container .semicolon {
  font-size: 36px;
  font-weight: 700;
  color: #727272;
  line-height: 1.1;
  padding: 0 3px;
}
.auctionItemWrap .auctionItem .countdownWrap .timer-container .timer {
  font-size: 40px;
  font-weight: 700;
  color: #727272;
  line-height: 1;
}
.auctionItemWrap .auctionItem .countdownWrap .timer-container .timer span {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #727272;
  text-align: center;
  text-transform: capitalize;
}
.ant-menu-horizontal {
  border-bottom: 0px;
}
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px;
}
.ant-breadcrumb + .ant-page-header {
  padding-top: 0;
}
.ant-message {
  top: inherit;
  bottom: 24px;
}
.ant-message-notice-content {
  background: #111;
  color: white;
  border-radius: 24px;
  max-width: 100%;
  padding: 24px / 1.7 48px;
}
.ant-message-custom-content {
  display: flex;
  text-align: left;
}
.ant-message-custom-content .anticon {
  margin-top: 3px;
}
@-webkit-keyframes antMoveUpIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
.main__page__header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 1rem 0;
  background-color: #14141F;
  box-shadow: 0 4px 28px rgba(255, 255, 255, 0.03);
  height: auto;
  --logo-size: 150px;
}
@media screen and (max-width: 767px) {
  .main__page__header {
    padding: 0.8rem 0;
  }
}
.main__page__header .logo__wrapper {
  height: auto;
}
@media screen and (max-width: 767px) {
  .main__page__header .logo__wrapper {
    width: var(--logo-size);
  }
}
.main__page__header .logo__wrapper img {
  display: block;
}
@media screen and (max-width: 767px) {
  .main__page__header .nav__wrapper {
    width: calc(100% - var(--logo-size));
  }
}
.main__page__header .col_etc {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}
.main__page__header .col_etc .search_wrap {
  position: relative;
  margin: 0;
  width: 100%;
  max-width: 460px;
}
@media screen and (max-width: 1560px) {
  .main__page__header .col_etc .search_wrap {
    max-width: 360px;
  }
}
@media screen and (max-width: 1279px) {
  .main__page__header .col_etc .search_wrap {
    max-width: 300px;
  }
}
@media screen and (max-width: 991px) {
  .main__page__header .col_etc .search_wrap {
    position: fixed;
    top: 60px;
    left: 0;
    max-width: 100%;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0 100vh 0px 100vh hsla(0, 0%, 0%, 0.75);
    transition: opacity 300ms;
  }
  .main__page__header .col_etc .search_wrap.open {
    opacity: 1;
    pointer-events: all;
    background: #14141f;
    border: dotted 1px rgba(255, 255, 255, 0.1);
    border-left: none;
    border-right: none;
    padding: 0 1rem;
  }
  .main__page__header .col_etc .search_wrap.open svg {
    left: 2rem;
  }
}
.main__page__header .col_etc .search_wrap svg {
  position: absolute;
  font-size: 1.125rem;
  top: 1.4rem;
  left: 1.4rem;
  color: #C61D9C;
}
.main__page__header .col_etc .search_wrap .input {
  border-radius: 50px;
  padding-left: 3em;
  width: 100%;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.8em 1em 0.8em 2.8em;
  outline: none;
  resize: none;
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  color: #FF31CA;
  transition: box-shadow 400ms ease-in-out;
  height: 51px;
}
@media screen and (max-width: 1560px) {
  .main__page__header .col_etc .search_wrap .input {
    height: 42px;
  }
}
.main__page__header .col_etc .btn_wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.main__page__header .col_etc .btn_wrap .btn {
  margin: 0;
  line-height: normal;
  min-height: 52px;
  min-width: 210px;
}
.main__page__header .col_etc .btn_wrap .btn b {
  font-weight: 600;
}
.main__page__header .col_etc .btn_wrap .btn .iconFont-add {
  font-size: 1.25em;
  color: #C847FF;
  transition: color 400ms;
}
.main__page__header .col_etc .btn_wrap .btn:hover .iconFont-add,
.main__page__header .col_etc .btn_wrap .btn:focus .iconFont-add {
  color: white;
}
.main__page__header .col_etc .btn_wrap .linkDropdown {
  display: flex;
  flex-wrap: wrap;
}
.main__page__header .col_etc .btn_wrap .linkDropdown .btn {
  border-radius: 2em 0 0 2em;
}
.main__page__header .col_etc .btn_wrap .linkDropdown .UserDropDown a {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #C61D9C;
  border-radius: 0 2rem 2rem 0;
  padding: 0 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.main__page__header .col_etc .btn_wrap .linkDropdown .UserDropDown a .ant-space-item {
  display: flex;
}
.main__page__header .col_etc .btn_wrap .linkDropdown .UserDropDown a .ant-space-item .anticon svg {
  color: #fff;
}
@media screen and (max-width: 991px) {
  .main__page__header .col_etc .btn_wrap .linkDropdown .UserDropDown a {
    padding: 0 12px;
  }
}
@media screen and (max-width: 991px) {
  .main__page__header .col_etc .btn_wrap .btn {
    min-width: auto;
    min-height: 38px;
  }
  .main__page__header .col_etc .btn_wrap .btn b {
    display: none;
  }
  .main__page__header .col_etc .btn_wrap .linkDropdown a {
    min-width: auto;
  }
  .main__page__header .col_etc .btn_wrap .linkDropdown a b {
    display: none;
  }
}
.main__page__header .col_etc .btn_themeMode {
  margin: 0;
  padding: 0;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
}
@media screen and (max-width: 1560px) {
  .main__page__header .col_etc .btn_themeMode {
    margin-left: 1rem;
  }
  .main__page__header .col_etc .btn_themeMode svg {
    max-width: 25px;
  }
}
@media screen and (max-width: 767px) {
  .main__page__header .col_etc .btn_themeMode {
    margin: 0;
  }
}
.main__page__header .col_etc .btn_search {
  display: none;
}
@media screen and (max-width: 991px) {
  .main__page__header .col_etc .btn_search {
    display: flex;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 767px) {
  .main__page__header .col_etc .btn {
    padding: 0;
    border-radius: 50px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .main__page__header .col_etc .btn b {
    display: none;
  }
  .main__page__header .col_etc .btn svg,
  .main__page__header .col_etc .btn img {
    max-width: 20px;
  }
}
.footer {
  background-color: #0D0D11;
  color: white;
  padding: 0;
}
.footer .content_wrap {
  max-width: 700px;
  margin: auto;
  text-align: center;
  font-size: 1.125rem;
  padding-block: 5rem;
}
@media screen and (max-width: 991px) {
  .footer .content_wrap {
    padding-block: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .footer .content_wrap {
    padding-block: 3rem;
  }
}
.footer .content_wrap .logo {
  display: inline-block;
  margin-bottom: 2rem;
}
.footer .content_wrap .logo img {
  min-height: 80px;
  margin: auto;
}
@media screen and (max-width: 1560px) {
  .footer .content_wrap .logo img {
    min-height: 70px;
  }
}
@media screen and (max-width: 991px) {
  .footer .content_wrap .logo img {
    min-height: 60px;
  }
}
@media screen and (max-width: 767px) {
  .footer .content_wrap .logo img {
    min-height: 50px;
  }
}
.footer .content_wrap ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .footer .content_wrap ul {
    gap: 1rem;
    margin-top: 2rem;
  }
}
.footer .content_wrap ul li {
  list-style: none;
}
.footer .content_wrap ul li .btn {
  font-size: 60px;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  padding: 0;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-size: 250% auto;
  transition: color 500ms, background-position 500ms;
}
.footer .content_wrap ul li .btn svg {
  width: 0.4em;
  transition: transform 500ms;
}
@media screen and (max-width: 1560px) {
  .footer .content_wrap ul li .btn {
    font-size: 50px;
  }
}
@media screen and (max-width: 767px) {
  .footer .content_wrap ul li .btn {
    font-size: 40px;
  }
}
.footer .content_wrap ul li .btn:hover,
.footer .content_wrap ul li .btn:focus {
  background-position: right center;
}
.footer .cpy_wrap {
  border-top: solid 1px rgba(255, 255, 255, 0.25);
  font-size: 1rem;
  padding: 2.5em;
  text-align: center;
  color: #ffffff;
}
@media screen and (max-width: 991px) {
  .footer .cpy_wrap {
    padding: 2rem;
  }
}
.footer .cpy_wrap p {
  margin: 0;
}
.container,
.container_sm,
.container_lg {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 1560px) {
  .container,
  .container_sm,
  .container_lg {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}
@media (min-width: 576px) {
  .container,
  .container_sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container_sm {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1200px;
  }
  .container_sm {
    max-width: 1000px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1300px;
  }
  .container_sm {
    max-width: 1200px;
  }
}
@media (min-width: 1561px) {
  .container {
    max-width: 1560px;
  }
  .container_sm {
    max-width: 1350px;
  }
}
.banner {
  background-color: #0D0D11;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.banner .ant-row,
.banner .row {
  align-items: center;
}
.banner .col_content h1 {
  font-size: 4.25rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
  margin-bottom: 0.4em;
}
@media screen and (max-width: 1560px) {
  .banner .col_content h1 {
    font-size: 3.75rem;
  }
}
@media screen and (max-width: 1279px) {
  .banner .col_content h1 {
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 767px) {
  .banner .col_content h1 {
    font-size: 2.75rem;
  }
}
.banner .col_content h1 span {
  display: block;
  font-size: 115%;
  background-image: linear-gradient(to left, #1150B8, #37C7F4, #FF31CA);
  background-clip: text;
  -webkit-text-stroke: 3px transparent;
  color: #0D0D11;
  letter-spacing: -1px;
}
.banner .col_content h1 img {
  display: block;
  margin-top: 1rem;
}
@media screen and (max-width: 1279px) {
  .banner .col_content h1 img {
    max-width: 180px;
  }
}
@media screen and (max-width: 767px) {
  .banner .col_content h1 img {
    max-width: 160px;
  }
}
.banner .col_content p {
  font-size: 1.125rem;
  max-width: 500px;
  line-height: 1.6;
  margin-bottom: 1em;
}
.banner .col_content .btn_wrap {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .banner .col_content .btn_wrap {
    margin-top: 3rem;
  }
}
.banner .col_content .btn_wrap .btn {
  min-width: 160px;
  margin-right: 1.25em;
}
@media screen and (max-width: 1560px) {
  .banner .col_content .btn_wrap .btn {
    min-width: 140px;
  }
}
@media screen and (max-width: 767px) {
  .banner .col_content .btn_wrap .btn {
    min-width: 130px;
  }
}
@media screen and (max-width: 991px) {
  .banner .col_img {
    display: none;
  }
}
.banner .col_img .img_orbit {
  --size: 130px;
  --speed: 120s;
  min-height: calc(var(--size) * 5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  isolation: isolate;
}
@media screen and (max-width: 1560px) {
  .banner .col_img .img_orbit {
    --size: 110px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit {
    --size: 90px;
  }
}
.banner .col_img .img_orbit .waveShape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}
.banner .col_img .img_orbit .circleShape {
  --size: 160px;
  position: absolute;
  top: 6%;
  left: 12%;
  width: var(--size);
  height: var(--size);
  animation: orbit 60s linear infinite;
  opacity: 1;
}
@media screen and (max-width: 1560px) {
  .banner .col_img .img_orbit .circleShape {
    --size: 130px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit .circleShape {
    --size: 100px;
  }
}
.banner .col_img .img_orbit .bg_shape .shape1,
.banner .col_img .img_orbit .bg_shape .shape2,
.banner .col_img .img_orbit .bg_shape .shape3 {
  border-radius: 0;
  position: absolute;
  animation: orbit2 20s linear infinite;
}
.banner .col_img .img_orbit .bg_shape .shape1 {
  top: 44%;
  left: 4%;
}
@media screen and (max-width: 1560px) {
  .banner .col_img .img_orbit .bg_shape .shape1 {
    max-width: 42px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit .bg_shape .shape1 {
    max-width: 36px;
  }
}
.banner .col_img .img_orbit .bg_shape .shape2 {
  bottom: 16%;
  right: 0;
}
@media screen and (max-width: 1560px) {
  .banner .col_img .img_orbit .bg_shape .shape2 {
    bottom: 15%;
    right: 10%;
    max-width: 140px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit .bg_shape .shape2 {
    max-width: 110px;
  }
}
.banner .col_img .img_orbit .bg_shape .shape3 {
  top: 35%;
  right: 18%;
  max-width: 34px;
}
@keyframes orbit2 {
  0%,
  100% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(10deg) translateY(-15px);
  }
}
.banner .col_img .img_orbit img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 80px;
}
@media screen and (max-width: 1560px) {
  .banner .col_img .img_orbit img {
    border-radius: 60px;
  }
}
.banner .col_img .img_orbit .center_img {
  width: var(--size);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}
.banner .col_img .img_orbit ul {
  display: grid;
  place-items: center;
  width: var(--size);
  height: calc(var(--size) * 1.54);
  margin: auto;
  position: relative;
  list-style: none;
  transform-origin: center;
  animation: orbit var(--speed) linear infinite;
}
.banner .col_img .img_orbit ul li {
  position: absolute;
  width: var(--size);
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  display: grid;
  place-items: center;
}
.banner .col_img .img_orbit ul li:hover {
  --throb-play-state: paused;
}
.banner .col_img .img_orbit ul li:nth-child(1) {
  --throb-delay: (500ms * 1);
}
.banner .col_img .img_orbit ul li:nth-child(2) {
  --throb-delay: (500ms * 2);
}
.banner .col_img .img_orbit ul li:nth-child(3) {
  --throb-delay: (500ms * 3);
}
.banner .col_img .img_orbit ul li:nth-child(4) {
  --throb-delay: (500ms * 4);
}
.banner .col_img .img_orbit ul li:nth-child(5) {
  --throb-delay: (500ms * 5);
}
.banner .col_img .img_orbit ul li:nth-child(6) {
  --throb-delay: (500ms * 6);
}
.banner .col_img .img_orbit ul li > div {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  animation: holdPosition var(--speed) linear infinite;
  animation-play-state: var(--orbit-play-state, running), var(--throb-play-state, running);
}
.banner .col_img .img_orbit ul li:nth-child(1) {
  transform: translate(0, -15rem);
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit ul li:nth-child(1) {
    transform: translate(0, -12rem);
  }
}
.banner .col_img .img_orbit ul li:nth-child(2) {
  transform: translate(12rem, -7.5rem);
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit ul li:nth-child(2) {
    transform: translate(10rem, -6rem);
  }
}
.banner .col_img .img_orbit ul li:nth-child(3) {
  transform: translate(12rem, 7.5rem);
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit ul li:nth-child(3) {
    transform: translate(10rem, 6rem);
  }
}
.banner .col_img .img_orbit ul li:nth-child(4) {
  transform: translate(0rem, 15rem);
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit ul li:nth-child(4) {
    transform: translate(0rem, 12rem);
  }
}
.banner .col_img .img_orbit ul li:nth-child(5) {
  transform: translate(-12rem, 7.5rem);
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit ul li:nth-child(5) {
    transform: translate(-10rem, 6rem);
  }
}
.banner .col_img .img_orbit ul li:nth-child(6) {
  transform: translate(-12rem, -7.5rem);
}
@media screen and (max-width: 1279px) {
  .banner .col_img .img_orbit ul li:nth-child(6) {
    transform: translate(-10rem, -6rem);
  }
}
@keyframes orbit {
  100% {
    rotate: 1turn;
  }
}
@keyframes holdPosition {
  100% {
    rotate: -1turn;
  }
}
.banner .cr_wrap.cr_primary {
  --size: 200px;
  top: unset;
  bottom: 20%;
  left: unset;
  right: calc(var(--size) / -2);
  opacity: 0.75;
}
@media screen and (max-width: 991px) {
  .banner .cr_wrap.cr_primary {
    --size: 150px;
  }
}
@media screen and (max-width: 767px) {
  .banner .cr_wrap.cr_primary {
    --size: 120px;
  }
}
.banner .cr_wrap.cr_secondary {
  --size: 200px;
  top: 40%;
  left: calc(var(--size) / -1.25);
  right: unset;
}
@media screen and (max-width: 991px) {
  .banner .cr_wrap.cr_secondary {
    --size: 150px;
  }
}
@media screen and (max-width: 767px) {
  .banner .cr_wrap.cr_secondary {
    --size: 120px;
  }
}
.banner .cr_wrap.cr_secondary.cr2 {
  --size: 250px;
  background-color: #5789DB;
  left: unset;
  top: calc(var(--size) / -2);
  right: 20%;
  opacity: 0.5;
}
@media screen and (max-width: 1279px) {
  .banner .cr_wrap.cr_secondary.cr2 {
    --size: 200px;
  }
}
@media screen and (max-width: 767px) {
  .banner .cr_wrap.cr_secondary.cr2 {
    --size: 150px;
  }
}
.banner .small > .cr_wrap {
  --size: 60px;
  filter: none;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1560px) {
  .banner .small > .cr_wrap {
    --size: 50px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .small > .cr_wrap {
    --size: 40px;
  }
}
.banner .small > .cr_wrap.cr_primary {
  background-image: radial-gradient(#B84B81, #932568);
}
.banner .small > .cr_wrap.cr_secondary {
  background-image: radial-gradient(#22CDE8, #59A1EF);
}
.banner .small > .cr_wrap.cr1 {
  top: unset;
  bottom: 10%;
  left: 50%;
}
@media screen and (max-width: 1560px) {
  .banner .small > .cr_wrap.cr1 {
    bottom: 8%;
  }
}
@media screen and (max-width: 991px) {
  .banner .small > .cr_wrap.cr1 {
    left: 60%;
  }
}
@media screen and (max-width: 767px) {
  .banner .small > .cr_wrap.cr1 {
    bottom: 5%;
    left: 80%;
  }
}
.banner .small > .cr_wrap.cr2 {
  --size: 30px;
  top: 8%;
  left: 3%;
}
@media screen and (max-width: 1279px) {
  .banner .small > .cr_wrap.cr2 {
    --size: 20px;
  }
}
@media screen and (max-width: 991px) {
  .banner .small > .cr_wrap.cr2 {
    top: 4%;
  }
}
@media screen and (max-width: 767px) {
  .banner .small > .cr_wrap.cr2 {
    top: -1%;
    left: 2%;
  }
}
.banner .small > .cr_wrap.cr3 {
  --size: 50px;
  top: 12%;
  left: unset;
  right: 3%;
}
@media screen and (max-width: 1560px) {
  .banner .small > .cr_wrap.cr3 {
    --size: 40px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .small > .cr_wrap.cr3 {
    --size: 30px;
  }
}
.banner .small > .cr_wrap.cr4 {
  --size: 30px;
  top: unset;
  bottom: 3%;
  left: unset;
  right: 25%;
}
@media screen and (max-width: 1560px) {
  .banner .small > .cr_wrap.cr4 {
    --size: 20px;
  }
}
@media screen and (max-width: 1279px) {
  .banner .small > .cr_wrap.cr4 {
    --size: 15px;
  }
}
.products_section {
  background-color: #14141F;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 991px) {
  .products_section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.products_section .cr_wrap.cr_primary {
  top: 60%;
  left: calc(var(--size) / -1.5);
  opacity: 0.5;
}
.products_section .cr_wrap.cr_secondary {
  top: 20%;
  right: calc(var(--size) / -1.5);
}
.products_section .col_link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
}
.products_section .col_link .btn {
  margin-right: 1em;
}
.products_section .col_link .linkDropdown > a {
  font-family: 'Urbanist', sans-serif;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  line-height: normal;
  height: auto;
  column-gap: 0.5em;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 0.7em 1.75em;
  border-radius: 2em;
  color: #ffffff;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  background-color: transparent;
  transition: color 500ms, background-color 500ms, border-color 500ms, transform 500ms, box-shadow 500ms, background-image 500ms, background-position 500ms, background-size 500ms;
  transition-timing-function: ease-in-out;
}
.products_section .col_link .linkDropdown > a.active,
.products_section .col_link .linkDropdown > a:hover,
.products_section .col_link .linkDropdown > a:focus {
  color: #ffffff;
  background-color: #343444;
}
.products_section .grid_wrap {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 767px) {
  .products_section .grid_wrap {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
.products_list .col_wrap {
  --contTop: 100px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .products_list .col_wrap {
    max-width: 380px;
    margin-inline: auto;
  }
}
.products_list .col_wrap .wei_wrap {
  --left: 1.5rem;
  position: absolute;
  top: 2rem;
  left: 0.29rem;
  z-index: 9;
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 0.7em 1.6em;
  padding-bottom: 1.45em;
  padding-left: 1em;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
  background-position: right center;
  clip-path: polygon(0 0, 0 calc(100% - 1rem), 1.25rem 100%, 1.25rem calc(100% - 1rem), 100% calc(100% - 1rem), 100% calc(100% - 1rem), 85% 40%, 100% 0, 100% 0);
}
.products_list .col_wrap .wei_wrap::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--left);
  height: 1rem;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
  filter: brightness(0.8);
}
@media screen and (max-width: 1560px) {
  .products_list .col_wrap .wei_wrap {
    --left: 1.7rem;
  }
}
.products_list .col_wrap .img_wrap {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  max-width: calc(100% - 3rem);
  margin: auto;
  z-index: 2;
}
.products_list .col_wrap .img_wrap .ant-image {
  display: block;
}
.products_list .col_wrap .img_wrap .ant-image img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  transition: filter 500ms;
}
.products_list .col_wrap .img_wrap .btn {
  position: absolute;
  z-index: 9;
  bottom: -4rem;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 100ms, background-position 500ms;
}
.products_list .col_wrap .content_wrap {
  background-color: #343444;
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  margin-top: calc(var(--contTop) / -1);
  padding-top: calc(var(--contTop) + 2rem);
  position: relative;
}
.products_list .col_wrap .content_wrap::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-image: linear-gradient(to bottom, #BB2AA2, #7171C5);
  border-radius: 1rem;
  z-index: -1;
  pointer-events: none;
  transform-origin: top left;
  transition: transform 500ms 100ms;
}
.products_list .col_wrap .content_wrap h3,
.products_list .col_wrap .content_wrap a {
  color: #FF31CA;
  font-size: 1.65rem;
  font-weight: 600;
  text-decoration: none;
}
.products_list .col_wrap .content_wrap h3 span,
.products_list .col_wrap .content_wrap a span {
  vertical-align: middle;
  display: inline-block;
  font-size: 1rem;
  padding: 0.2em 0.8em;
  margin-left: 0.5em;
  color: white;
  font-weight: 500;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
  border-radius: 2em;
}
.products_list .col_wrap .content_wrap a:hover,
.products_list .col_wrap .content_wrap a:focus {
  color: #C61D9C;
}
.products_list .col_wrap .content_wrap p {
  font-size: 1.25rem;
  margin-bottom: 0.7em;
}
.products_list .col_wrap .content_wrap small {
  font-size: 1rem;
}
.products_list .col_wrap:hover .img_wrap img,
.products_list .col_wrap:focus .img_wrap img {
  filter: brightness(0.5);
}
.products_list .col_wrap:hover .img_wrap .btn,
.products_list .col_wrap:focus .img_wrap .btn {
  bottom: 25%;
  transform: translateX(-50%) translateY(50%);
}
.products_list .col_wrap:hover .content_wrap::before,
.products_list .col_wrap:focus .content_wrap::before {
  transform: rotate(4deg);
}
.smallInnerBanner {
  background-color: #242431;
  min-height: 300px;
  background-blend-mode: screen;
  background-position: right top;
  background-size: auto;
}
.userInfoSection {
  margin-top: -110px;
}
.userInfoSection .userInfo .thumb img {
  border-radius: 50%;
  border: 5px solid #14141F;
  box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.4);
}
.userInfoSection .userInfo .userDetails {
  margin: 15px 0;
}
.userInfoSection .userInfo h3 {
  color: #fff;
  font-size: 34px;
  margin: 0;
  font-weight: 600;
}
.userInfoSection .userInfo span {
  color: #FF31CA;
  font-size: 18px;
  font-weight: 700;
}
.auctionDropDown .ant-dropdown-menu {
  border-radius: 20px;
  background: #14141F;
  box-shadow: 0px 4px 39px 0px rgba(255, 255, 255, 0.29);
  padding: 0px 15px;
  position: relative;
}
.auctionDropDown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
  background-color: transparent;
}
.auctionDropDown .ant-dropdown-menu .ant-dropdown-menu-item a {
  padding: 15px 0;
  color: #D9D9D9;
  font-size: 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  width: 100%;
}
.auctionDropDown .ant-dropdown-menu .ant-dropdown-menu-item .active {
  color: #C61D9C !important;
}
.auctionDropDown .ant-dropdown-menu .ant-dropdown-menu-item:last-child a {
  border-bottom: none;
}
.auctionDropDown .ant-dropdown-menu .ant-dropdown-menu-item:hover a {
  color: #C61D9C;
}
.auctionDropDown .ant-dropdown-menu::before {
  content: "";
  position: absolute;
  right: 30px;
  top: -25px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 32px 16px;
  border-color: transparent transparent #14141F transparent;
  display: none;
}
.ant-layout {
  background-color: #14141F;
}
@media screen and (max-width: 1279px) {
  .product_section {
    padding-block: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .product_section {
    padding-block: 3rem;
  }
}
.product_section .pr_details {
  margin-bottom: 2.5rem;
}
.product_section .pr_details .col_img img {
  border-radius: 1rem;
  width: 100%;
}
.product_section .pr_details .col_content {
  padding: 2rem 2.5rem;
}
@media screen and (max-width: 991px) {
  .product_section .pr_details .col_content {
    padding: 2rem 0;
  }
}
.product_section .pr_details .col_content h1 {
  font-size: 2.125rem;
  font-weight: 600;
  margin-bottom: 0.75em;
}
@media screen and (max-width: 767px) {
  .product_section .pr_details .col_content h1 {
    font-size: 1.875rem;
  }
}
.product_section .pr_details .col_content h1 span {
  vertical-align: middle;
  display: inline-block;
  font-size: 1rem;
  padding: 0.2em 1em;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
  border-radius: 2em;
}
.product_section .pr_details .col_content h2,
.product_section .pr_details .col_content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.75em 0;
}
.product_section .pr_details .col_content .content {
  margin-bottom: 3rem;
}
@media screen and (max-width: 1279px) {
  .product_section .pr_details .col_content .content {
    margin-bottom: 2rem;
  }
}
.product_section .pr_details .col_content p {
  color: rgba(255, 255, 255, 0.65);
  line-height: 1.6;
}
.product_section .pr_details .col_content strong {
  display: inline-block;
  border-radius: 0.875rem;
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
  font-size: 1.125rem;
  padding: 1.25em;
  font-weight: 500;
}
.product_section .tab__wrap .ant-tabs-nav {
  margin-bottom: 2.5rem;
}
.product_section .tab__wrap .ant-tabs-nav::before {
  border-color: rgba(255, 255, 255, 0.25);
}
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-nav-wrap::before {
  content: none;
}
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-ink-bar {
  background-color: transparent;
}
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0;
  padding: 0;
}
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  background-color: transparent;
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-bottom: none;
  border-top-left-radius: 0.75em;
  border-top-right-radius: 0.75em;
  font-size: 0.875rem;
  margin-right: 0.75em;
  padding: 0.7em 2em;
  color: white;
  margin-bottom: 0;
  background-size: 240% auto;
  background-position: left center;
  transition: background-color 500ms, background-position 500ms;
}
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:hover,
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:focus {
  background-color: rgba(255, 255, 255, 0.05);
}
.product_section .tab__wrap .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background-image: linear-gradient(to left, #6C76C7 0%, #C61D9C 40%, transparent 60%);
  color: #ffffff;
  background-position: right center;
}
.product_section .tab__wrap .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
  color: white;
}
.product_section .detailsTab {
  border: solid 1px rgba(255, 255, 255, 0.25);
  border-radius: 0.875rem;
  padding: 2rem 1rem;
  margin-bottom: 1.75rem;
}
@media screen and (max-width: 991px) {
  .product_section .detailsTab {
    padding: 1rem;
  }
}
.product_section .detailsTab .input_group {
  padding: 0 0.75rem;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .product_section .detailsTab .input_group {
    width: 100%;
    padding: 0;
  }
}
.product_section .detailsTab .input_group:last-child {
  margin-bottom: 0;
}
.product_section .detailsTab .input_group small {
  font-size: 1.125rem;
  font-weight: 400;
  padding-left: 0.2em;
  margin-bottom: 0.3em;
  display: block;
}
.product_section .detailsTab .input_group .input {
  display: block;
  font-size: 1.375rem;
  font-weight: 600;
  padding: 0.85em 1em;
  margin: 0;
  color: #FF31CA;
}
@media screen and (max-width: 1560px) {
  .product_section .detailsTab .input_group .input {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 1279px) {
  .product_section .detailsTab .input_group .input {
    font-size: 1.125rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 991px) {
  .product_section .detailsTab .input_group .input {
    font-size: 1rem;
  }
}
.retaled_slider {
  margin-top: 2rem;
}
.retaled_slider > h3 {
  font-size: 2.125rem;
  font-weight: 600;
}
.retaled_slider .slick_related .slick_item {
  padding: 0.8rem;
}
.retaled_slider .slick_related .slick_item .col_wrap {
  --contTop: 80px;
}
.retaled_slider .slick_related .slick_item .col_wrap .img_wrap {
  max-width: calc(100% - 2rem);
}
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap {
  padding: 1.5rem;
  padding-top: calc(var(--contTop) + 1.5rem);
  padding-bottom: 2rem;
}
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap h3,
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap a {
  font-size: 1.325rem;
  margin-bottom: 1em;
}
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap h3 a,
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap a a {
  margin: 0;
}
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap p {
  font-size: 1.125rem;
}
.retaled_slider .slick_related .slick_item .col_wrap .content_wrap small {
  font-size: 1rem;
}
.retaled_slider .slick_related .slick-arrow {
  --btn-size: 42px;
  font-size: 0px;
  padding: 0;
  border: none;
  width: var(--btn-size);
  height: var(--btn-size);
  border-radius: var(--btn-size);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: calc(var(--btn-size) / -1);
  border: solid 1px #ffffff;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.25;
  transition: opacity 500ms;
  z-index: 9;
}
@media screen and (max-width: 1279px) {
  .retaled_slider .slick_related .slick-arrow {
    right: -0.5rem;
  }
}
.retaled_slider .slick_related .slick-arrow.slick-prev {
  right: unset;
  left: calc(var(--btn-size) / -1);
}
@media screen and (max-width: 1279px) {
  .retaled_slider .slick_related .slick-arrow.slick-prev {
    left: -0.5rem;
  }
}
.retaled_slider .slick_related .slick-arrow.slick-prev::before {
  transform: scaleX(-1);
}
.retaled_slider .slick_related .slick-arrow::before {
  content: "\e00f";
  display: inline-block;
  font-family: "BlockChainIcons" !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.125rem;
}
.retaled_slider .slick_related .slick-arrow:hover,
.retaled_slider .slick_related .slick-arrow:focus {
  opacity: 0.75;
}
hr {
  border-color: rgba(255, 255, 255, 0.3);
}
.ant-modal .ant-modal-body {
  padding: 0;
}
.ant-modal .ant-modal-content {
  background-color: #14141F;
  color: white;
  border-radius: 1rem;
  padding: 0rem;
  box-shadow: 0 4px 40px rgba(255, 255, 255, 0.2);
}
.ant-modal .btn_modalClose {
  padding: 0;
  min-width: 34px;
  height: 34px;
  font-size: 0.875rem;
}
.tabHeader {
  padding: 0;
  padding-block: 1rem;
  border-bottom: dotted 1px rgba(255, 255, 255, 0.25);
  margin: 0;
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  counter-reset: tabCout;
}
@media screen and (max-width: 767px) {
  .tabHeader {
    gap: 1rem;
    padding-block: 0.5rem;
    flex-direction: row;
  }
}
.tabHeader .ant-steps-item {
  counter-increment: tabCout;
  list-style: none;
  display: inline-flex;
  align-items: center;
  padding: 0;
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  position: relative;
  width: auto;
  flex: none;
}
.tabHeader .ant-steps-item::before {
  content: counter(tabCout);
  display: inline-block;
  font-size: 4rem;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.15);
  margin-right: -0.4em;
}
.tabHeader .ant-steps-item .ant-steps-item-container {
  outline: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
  font-size: 1.75rem;
  width: 2.2em;
  height: 2.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2em;
  background-color: transparent;
  color: #C1229E;
  border: none;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  position: relative;
  isolation: isolate;
}
.tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-icon::after {
  content: '';
  position: absolute;
  inset: calc(15px / -2);
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  border-radius: 50px;
  border: dotted 2px rgba(255, 255, 255, 0.25);
  z-index: -1;
  opacity: 0;
  transition: opacity 500ms;
}
.tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  font-size: 0px;
  display: inline-block;
  font-family: "BlockChainIcons" !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #C1229E;
}
.tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon::before {
  content: "\e007";
  font-size: 1.5rem;
}
@media screen and (max-width: 767px) {
  .tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-tail {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-content {
    display: none;
  }
}
.tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  font-size: 1.25rem;
  font-weight: 400;
  color: white;
}
.tabHeader .ant-steps-item .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title::after {
  content: none;
}
.tabHeader .ant-steps-item:nth-child(2) .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon::before {
  content: "\e00e";
  font-size: 1.75rem;
}
.tabHeader .ant-steps-item:nth-child(3) .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon::before {
  content: "\e012";
  font-size: 1.75rem;
}
.tabHeader .ant-steps-item.ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon {
  background-image: linear-gradient(to right, #6C76C7 0%, #C61D9C 51%, #6C76C7 100%);
  background-size: 200% auto;
  color: white;
}
.tabHeader .ant-steps-item.ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  color: white;
}
.tabHeader .ant-steps-item.ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon:after,
.tabHeader .ant-steps-item.ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon::before {
  opacity: 1;
}
.tabHeader .ant-steps-item.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon {
  border-color: #C1229E;
}
.tabHeader .ant-steps-item.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  color: #C1229E;
}
.tab_container {
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .tab_container {
    padding: 1.5rem;
  }
}
.tab_listing .btn_wrap {
  text-align: center;
  margin-top: 1.5rem;
}
.listing_wrap {
  position: relative;
}
.listing_wrap::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  pointer-events: none;
  background-image: linear-gradient(to bottom, transparent, #14141F);
}
.listing_wrap ul {
  margin: 0;
  padding: 0;
  max-height: 65dvh;
  overflow: auto;
  padding-left: 1rem;
  margin-left: -1rem;
  padding-right: 1rem;
  margin-right: -1rem;
}
.listing_wrap ul::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
@media screen and (max-width: 767px) {
  .listing_wrap ul::-webkit-scrollbar {
    width: 3px;
  }
}
.listing_wrap ul::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.03);
  border-radius: 4px;
}
.listing_wrap ul::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #BD28A0, #7171C5);
  border-radius: 4px;
}
.listing_wrap ul li {
  list-style: none;
  display: grid;
  grid-template-columns: 85px 1fr;
  column-gap: 2rem;
  padding: 1rem 2.5rem;
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  background-color: #343444;
  border-radius: 1rem;
  transition: box-shadow 500ms, background-color 500ms;
}
@media screen and (max-width: 1560px) {
  .listing_wrap ul li {
    grid-template-columns: 75px 1fr;
    border-radius: 0.875rem;
  }
}
@media screen and (max-width: 1279px) {
  .listing_wrap ul li {
    grid-template-columns: 70px 1fr;
    column-gap: 1.5rem;
    border-radius: 0.875rem;
  }
}
@media screen and (max-width: 991px) {
  .listing_wrap ul li {
    grid-template-columns: 65px 1fr;
  }
}
@media screen and (max-width: 767px) {
  .listing_wrap ul li {
    padding: 1rem;
    column-gap: 1rem;
  }
}
.listing_wrap ul li .img_wrap {
  border-radius: 1rem;
  margin-top: -2.5rem;
  aspect-ratio: 1;
  overflow: hidden;
  transition: transform 500ms;
}
@media screen and (max-width: 1279px) {
  .listing_wrap ul li .img_wrap {
    margin-top: -2rem;
  }
}
.listing_wrap ul li .img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.listing_wrap ul li .content {
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (max-width: 767px) {
  .listing_wrap ul li .content {
    font-size: 1.125rem;
  }
}
.listing_wrap ul li .content p {
  margin: 0;
}
.listing_wrap ul li .content small {
  font-size: 1.125rem;
  font-weight: 400;
  color: #C847FF;
}
@media screen and (max-width: 767px) {
  .listing_wrap ul li .content small {
    font-size: 1rem;
  }
}
.listing_wrap ul li:hover,
.listing_wrap ul li:focus {
  cursor: pointer;
  background-color: #4a4a61;
  box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.6);
}
.quantity_wrap::before {
  content: none;
}
.quantity_wrap ul li {
  grid-template-columns: 120px repeat(4, 1fr);
  column-gap: 1.5rem;
}
@media screen and (max-width: 1560px) {
  .quantity_wrap ul li {
    grid-template-columns: 100px repeat(4, 1fr);
  }
}
@media screen and (max-width: 1279px) {
  .quantity_wrap ul li {
    grid-template-columns: 80px repeat(4, 1fr);
  }
}
@media screen and (max-width: 991px) {
  .quantity_wrap ul li {
    grid-template-columns: 80px repeat(4, 1fr);
    row-gap: 1rem;
  }
}
.quantity_wrap ul li .img_wrap {
  margin-top: -2rem;
}
.quantity_wrap ul li .title {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
}
@media screen and (max-width: 1560px) {
  .quantity_wrap ul li .title {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 991px) {
  .quantity_wrap ul li .title {
    grid-column: span 4;
  }
}
@media screen and (max-width: 991px) {
  .quantity_wrap ul li .price {
    grid-column: span 3;
  }
}
@media screen and (max-width: 767px) {
  .quantity_wrap ul li .price {
    grid-column: span 5;
  }
}
@media screen and (max-width: 767px) {
  .quantity_wrap ul li .available {
    grid-column: span 4;
  }
}
.quantity_wrap ul li strong {
  color: #FF31CA;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.25em;
  display: block;
}
.quantity_wrap ul li p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}
.total_price {
  border: dotted 1px rgba(255, 255, 255, 0.25);
  padding: 2rem 2.5rem;
  border-radius: 1rem;
  margin: 2rem 0;
}
@media screen and (max-width: 767px) {
  .total_price {
    padding: 1.5rem 2rem;
  }
}
.total_price p {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
}
@media screen and (max-width: 1560px) {
  .total_price p {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .total_price p {
    font-size: 1.25rem;
  }
}
.total_price strong {
  color: #FF31CA;
  font-size: 2.125rem;
  font-weight: 700;
}
@media screen and (max-width: 1560px) {
  .total_price strong {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  .total_price strong {
    font-size: 1.5rem;
  }
}
.counter_wrap {
  display: inline-flex;
  --height: 60px;
}
@media screen and (max-width: 1560px) {
  .counter_wrap {
    --height: 52px;
  }
}
@media screen and (max-width: 1279px) {
  .counter_wrap {
    --height: 48px;
  }
}
.counter_wrap span,
.counter_wrap button {
  background-color: transparent;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0em;
  margin: 0;
  border: solid 1px rgba(255, 255, 255, 0.25);
  border-radius: 1rem;
  min-width: var(--height);
  height: var(--height);
}
.counter_wrap span {
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 0;
  border-left: none;
  border-right: none;
}
.counter_wrap button {
  cursor: pointer;
}
.counter_wrap button:active {
  background-color: rgba(255, 255, 255, 0.1);
}
.counter_wrap button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.counter_wrap button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.purchase_wrap {
  padding: 1rem;
}
@media screen and (max-width: 767px) {
  .purchase_wrap {
    padding: 0rem;
  }
}
.purchase_wrap .col_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}
.purchase_wrap .col_content {
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .purchase_wrap .col_content {
    padding: 0;
    padding-top: 1.5rem;
  }
}
.purchase_wrap .col_content h3 {
  font-size: 2.125rem;
  font-weight: 600;
}
@media screen and (max-width: 1560px) {
  .purchase_wrap .col_content h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .purchase_wrap .col_content h3 {
    font-size: 1.75rem;
  }
}
.purchase_wrap .col_content p {
  font-size: 1.5rem;
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .purchase_wrap .col_content p {
    font-size: 1.25rem;
  }
}
.purchase_wrap .col_content .btn {
  text-transform: uppercase;
}
.purchase_wrap .qrRow {
  border: dotted 2px rgba(255, 255, 255, 0.25);
  padding: 1.5rem;
  border-radius: 1rem;
  margin-top: 2rem;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .purchase_wrap .qrRow .col_content {
    width: 100%;
    text-align: center;
    padding: 0;
  }
}
.purchase_wrap .qrRow .col_content strong {
  color: #FF31CA;
  font-size: 1.5rem;
  font-weight: 400;
}
@media screen and (max-width: 1560px) {
  .purchase_wrap .qrRow .col_content strong {
    font-size: 1.25rem;
  }
}
.purchase_wrap .qrRow .col_content p {
  font-size: 1.75rem;
  font-weight: 400;
}
@media screen and (max-width: 1560px) {
  .purchase_wrap .qrRow .col_content p {
    font-size: 1.5rem;
  }
}
.purchase_wrap .qrRow .col_qr {
  border-left: dotted 2px rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 767px) {
  .purchase_wrap .qrRow .col_qr {
    width: 100%;
    border: none;
  }
}
.purchase_wrap .qrRow .col_qr img {
  display: block;
  margin: auto;
  max-width: 200px;
}
@media screen and (max-width: 1560px) {
  .purchase_wrap .qrRow .col_qr img {
    max-width: 180px;
  }
}
@media screen and (max-width: 1279px) {
  .purchase_wrap .qrRow .col_qr img {
    max-width: 150px;
  }
}
@media screen and (max-width: 1560px) {
  .addProduct {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .addProduct {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.addProduct h2 {
  font-size: 2.125rem;
  font-weight: 600;
}
@media screen and (max-width: 1560px) {
  .addProduct h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 991px) {
  .addProduct h2 {
    font-size: 1.75rem;
  }
}
.addProduct hr {
  margin-bottom: 1rem;
}
.addProduct p {
  color: rgba(255, 255, 255, 0.65);
}
.addProduct .borderWhite {
  border: solid 1px rgba(255, 255, 255, 0.25);
  border-radius: 0.875rem;
  padding: 2rem 1.5rem;
  margin-bottom: 1.75rem;
}
.addProduct .input_group {
  padding: 0 0.75rem;
}
@media screen and (max-width: 767px) {
  .addProduct .input_group {
    width: 100%;
    padding: 0;
  }
}
.addProduct .input_group .ant-form-item {
  margin-bottom: 0;
}
.addProduct .input_group .btn_or {
  padding: 0.5em 0.75em;
}
.ant-upload.ant-upload-drag {
  background: transparent;
  border-radius: 8px;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 1.25rem 0;
}
.ant-upload.ant-upload-drag .ant-upload .ant-upload-text {
  color: rgba(255, 255, 255, 0.75);
}
.ant-upload.ant-upload-drag .ant-upload .ant-upload-drag-icon {
  margin: 0.75rem 0;
}
.ant-upload.ant-upload-drag .ant-upload .ant-upload-drag-icon svg {
  fill: rgba(200, 71, 255, 0.75);
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: rgba(200, 71, 255, 0.75);
}
.ant-upload-list-item {
  color: rgba(255, 255, 255, 0.8);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: rgba(255, 255, 255, 0.05);
}
.ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  color: rgba(255, 255, 255, 0.8);
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #ff4d4f;
}
.detailsTab_table {
  padding: 0 !important;
  overflow: hidden;
}
.ant-table-wrapper {
  width: 100%;
}
.ant-table-wrapper .ant-table {
  background: transparent;
}
.ant-table-wrapper .ant-table .ant-table-thead > tr > th {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.05);
  color: white;
  white-space: nowrap;
  font-size: 1.125rem;
  font-weight: 600;
}
.ant-table-wrapper .ant-table .ant-table-thead > tr > th:first-child {
  padding-left: 1.5rem;
}
.ant-table-wrapper .ant-table .ant-table-thead > tr > th:last-child {
  padding-right: 1.5rem;
}
.ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: inherit !important;
}
.ant-table-wrapper .ant-table .ant-table-tbody > tr > td {
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
}
.ant-table-wrapper .ant-table .ant-table-tbody > tr > td:first-child {
  padding-left: 1.5rem;
}
.ant-table-wrapper .ant-table .ant-table-tbody > tr > td:last-child {
  padding-right: 1.5rem;
}
.ant-table-wrapper .ant-table .ant-table-tbody > tr > td .ant-tag {
  background-color: transparent;
  color: inherit;
  border-radius: 5px;
}
.ant-table-wrapper .ant-table .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-wrapper .ant-table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: rgba(255, 255, 255, 0.02);
}
.ant-form-item .ant-picker .ant-picker-input > input {
  color: #FF31CA;
  font-size: 1.125rem;
}
.ant-form-item .ant-picker .ant-picker-suffix {
  padding-left: 20px;
  position: relative;
  font-size: 20px;
}
.ant-form-item .ant-picker .ant-picker-suffix::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 36px;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.25);
}
.ant-form-item .ant-picker .ant-picker-suffix .anticon svg {
  width: 24px;
}
.ant-form-item .ant-picker .ant-picker-suffix .anticon svg path {
  fill: rgba(255, 255, 255, 0.25);
}
.auctionModel .ant-modal-body {
  padding: 40px;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item label {
  font-size: 20px;
  color: #fff;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item label span {
  font-size: 16px;
  color: #fff;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number {
  background-color: #14141F;
  border: none;
  width: 100%;
  max-width: 180px;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number .ant-input-number-input {
  width: 100%;
  height: auto;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0.8em 1em !important;
  border-radius: 8px;
  outline: none;
  resize: none;
  background-color: rgba(255, 255, 255, 0.05) !important;
  border: none;
  color: #FF31CA;
  transition: box-shadow 400ms ease-in-out;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number .ant-input-number-input:focus {
  box-shadow: 0 0 0px 2px rgba(200, 71, 255, 0.75);
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap {
  background-color: rgba(255, 255, 255, 0.05);
  width: 30px;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap .ant-input-number-handler span svg {
  width: 20px;
  height: 20px;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap .ant-input-number-handler span svg path {
  fill: #fff;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  right: 8px;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap .ant-input-number-handler {
  height: 50% !important;
}
.auctionModel .ant-modal-body .ant-form .ant-form-item .ant-input-number-handler-wrap .ant-input-number-handler:hover {
  height: 50% !important;
}
.auctionModel .ant-modal-footer {
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 20px;
}
.register__section,
.login__section {
  min-height: calc(100vh - 64px);
  background-color: #3fb0fb;
  display: flex;
  justify-content: center;
  align-items: center;
}
