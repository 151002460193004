.container,
.container_sm,
.container_lg {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto;

	@media screen and (max-width: @max-1560) {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (min-width: 576px) {

	.container,
	.container_sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {

	.container,
	.container_sm {
		max-width: 750px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 1200px;
	}

	.container_sm {
		max-width: 1000px;
	}
}

@media (min-width: 1280px) {
	.container {
		max-width: 1300px;
	}

	.container_sm {
		max-width: 1200px;
	}
}

@media (min-width: 1561px) {
	.container {
		max-width: 1560px;
	}

	.container_sm {
		max-width: 1350px;
	}
}