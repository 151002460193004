 @font-face {
    font-display: auto;
    font-family: "BlockChainIcons";
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/iconly.eot?1688643768299");
    src: url("../fonts/iconly.eot?#iefix") format("embedded-opentype"),
        url("../fonts/iconly.woff2?1688643768299") format("woff2"),
        url("../fonts/iconly.woff?1688643768299") format("woff"),
        url("../fonts/iconly.ttf?1688643768299") format("truetype"),
        url("../fonts/iconly.svg?1688643768299#BlockChainIcons") format("svg");
}

[class="iconFont"],
[class^="iconFont-"],
[class*=" iconFont-"] {
    display: inline-block;
    font-family: "BlockChainIcons" !important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.iconFont-youtube:before {
    content: "\e000";
}

.iconFont-wallet:before {
    content: "\e001";
}

.iconFont-twitter:before {
    content: "\e002";
}

.iconFont-search:before {
    content: "\e003";
}

.iconFont-reddit:before {
    content: "\e004";
}

.iconFont-plus:before {
    content: "\e005";
}

.iconFont-minus:before {
    content: "\e006";
}

.iconFont-list:before {
    content: "\e007";
}

.iconFont-light:before {
    content: "\e008";
}

.iconFont-instagram:before {
    content: "\e009";
}

.iconFont-facebook:before {
    content: "\e00a";
}

.iconFont-discord:before {
    content: "\e00b";
}

.iconFont-close:before {
    content: "\e00c";
}

.iconFont-cart:before {
    content: "\e00d";
}

.iconFont-box:before {
    content: "\e00e";
}

.iconFont-arrow-right:before {
    content: "\e00f";
}

.iconFont-arrow-left:before {
    content: "\e010";
}

.iconFont-add:before {
    content: "\e011";
}

.iconFont-cart-line:before {
    content: "\e012";
}