.ant-layout {
    background-color: @clr-dark--mode;
}

.product_section {
    @media screen and (max-width: @max-1280) {
        padding-block: 5rem;
    }

    @media screen and (max-width: @max-767) {
        padding-block: 3rem;
    }

    // pr_details
    .pr_details {
        margin-bottom: 2.5rem;

        .col_img {
            img {
                border-radius: 1rem;
                width: 100%;
            }
        }

        .col_content {
            padding: 2rem 2.5rem;

            @media screen and (max-width: @max-991) {
                padding: 2rem 0;
            }

            h1 {
                font-size: 2.125rem;
                font-weight: 600;
                margin-bottom: 0.75em;

                @media screen and (max-width: @max-767) {
                    font-size: 1.875rem;
                }

                span {
                    vertical-align: middle;
                    display: inline-block;
                    font-size: 1rem;
                    padding: 0.2em 1em;
                    background-image: @gradient-400;
                    background-size: 200% auto;
                    border-radius: 2em;
                }
            }


            h2,h3 {
                font-size: 1.5rem;
                font-weight: 600;
                margin: 0.75em 0;
            }
            

            .content {
                margin-bottom: 3rem;

                @media screen and (max-width: @max-1280) {
                    margin-bottom: 2rem;
                }
            }

            p {
                color: rgba(@clr-dark--100, 65%);
                line-height: 1.6;
            }

            strong {
                display: inline-block;
                border-radius: 0.875rem;
                background-image: @gradient-400;
                background-size: 200% auto;
                font-size: 1.125rem;
                padding: 1.25em;
                font-weight: 500;
            }
        }
    }

    // tab__wrap
    .tab__wrap {
        .ant-tabs-nav {
            margin-bottom: 2.5rem;

            &::before {
                // content: none;
                border-color: rgba(@clr-dark--100, 25%);
            }

            .ant-tabs-nav-wrap {
                &::before {
                    content: none;
                }
            }

            .ant-tabs-ink-bar {
                background-color: transparent;
            }

            .ant-tabs-nav-list {
                .ant-tabs-tab {
                    margin: 0;
                    padding: 0;

                    .ant-tabs-tab-btn {
                        background-color: transparent;
                        border: solid 1px rgba(@clr-dark--100, 20%);
                        border-bottom: none;
                        border-top-left-radius: 0.75em;
                        border-top-right-radius: 0.75em;
                        font-size: 0.875rem;
                        margin-right: 0.75em;
                        padding: 0.7em 2em;
                        color: white;
                        margin-bottom: 0;
                        background-size: 240% auto;
                        background-position: left center;
                        transition: background-color 500ms, background-position 500ms;

                        &:hover,
                        &:focus {
                            background-color: rgba(@clr-dark--100, 5%);
                        }
                    }

                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            background-image: @gradient-100;
                            color: #ffffff;
                            background-position: right center;
                        }
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            .ant-tabs-content {
                .ant-tabs-tabpane {
                    color: white;
                }
            }
        }
    }

    // detailsTab
    .detailsTab {
        border: solid 1px rgba(@clr-dark--100, 25%);
        border-radius: 0.875rem;
        padding: 2rem 1rem;
        margin-bottom: 1.75rem;

        @media screen and (max-width: @max-991) {
            padding: 1rem;
        }

        .input_group {
            padding: 0 0.75rem;
            margin-bottom: 1rem;

            @media screen and (max-width: @max-767) {
                width: 100%;
                padding: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            small {
                font-size: 1.125rem;
                font-weight: 400;
                padding-left: 0.2em;
                margin-bottom: 0.3em;
                display: block;
            }

            .input {
                display: block;
                font-size: 1.375rem;
                font-weight: 600;
                padding: .85em 1em;
                margin: 0;
                color: #FF31CA;

                @media screen and (max-width: @max-1560) {
                    font-size: 1.25rem;
                }

                @media screen and (max-width: @max-1280) {
                    font-size: 1.125rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                @media screen and (max-width: @max-991) {
                    font-size: 1rem;
                }
            }
        }
    }


}


// retaled_slider
.retaled_slider {
    margin-top: 2rem;

    >h3 {
        font-size: 2.125rem;
        font-weight: 600;
    }

    .slick_related {
        .slick_item {
            padding: 0.8rem;

            .col_wrap {
                --contTop: 80px;

                .img_wrap {
                    max-width: calc(100% - 2rem);
                }

                .content_wrap {
                    padding: 1.5rem;
                    padding-top: calc(var(--contTop) + 1.5rem);
                    padding-bottom: 2rem;

                    h3,
                    a {
                        font-size: 1.325rem;
                        margin-bottom: 1em;

                        a {
                            margin: 0;
                        }
                    }

                    p {
                        font-size: 1.125rem;
                    }

                    small {
                        font-size: 1rem;
                    }
                }
            }
        }

        .slick-arrow {
            --btn-size: 42px;
            font-size: 0px;
            padding: 0;
            border: none;
            width: var(--btn-size);
            height: var(--btn-size);
            border-radius: var(--btn-size);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            right: calc(var(--btn-size) / -1);
            border: solid 1px @clr-dark--100;
            background-color: transparent;
            cursor: pointer;
            opacity: 0.25;
            transition: opacity 500ms;
            z-index: 9;

            @media screen and (max-width: @max-1280) {
                right: -0.5rem;
            }

            &.slick-prev {
                right: unset;
                left: calc(var(--btn-size) / -1);

                @media screen and (max-width: @max-1280) {
                    left: -0.5rem;
                }

                &::before {
                    transform: scaleX(-1);
                }
            }

            &::before {
                content: "\e00f";
                display: inline-block;
                font-family: "BlockChainIcons" !important;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-size: 1.125rem;

            }

            &:hover,
            &:focus {
                opacity: 0.75;
            }
        }
    }
}


hr {
    border-color: rgba(@clr-dark--100, 30%);
}



// modal
.ant-modal {
    .ant-modal-body {
        padding: 0;
    }

    .ant-modal-content {
        background-color: #14141F;
        color: white;
        border-radius: 1rem;
        padding: 0rem;
        box-shadow: 0 4px 40px rgba(@clr-dark--100, 0.20);
    }

    .btn_modalClose {
        padding: 0;
        min-width: 34px;
        height: 34px;
        font-size: 0.875rem;
    }
}



// Steps
.tabHeader {
    padding: 0;
    padding-block: 1rem;
    border-bottom: dotted 1px rgba(@clr-dark--100, 0.25);

    margin: 0;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    counter-reset: tabCout;

    @media screen and (max-width: @max-767) {
        gap: 1rem;
        padding-block: 0.5rem;
        flex-direction: row;
    }

    .ant-steps-item {
        counter-increment: tabCout;
        list-style: none;
        display: inline-flex;
        align-items: center;
        padding: 0;
        gap: 0.75rem;
        font-size: 1.25rem;
        font-weight: 400;
        position: relative;
        width: auto;
        flex: none;

        &::before {
            content: counter(tabCout);
            display: inline-block;
            font-size: 4rem;
            font-weight: bold;
            color: rgba(@clr-dark--100, 0.15);
            margin-right: -0.4em;

        }

        .ant-steps-item-container {
            outline: none;
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .ant-steps-item-icon {
                font-size: 1.75rem;
                width: 2.2em;
                height: 2.2em;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 2em;
                background-color: transparent;
                color: #C1229E;
                border: none;
                box-shadow: inset 0 0 0 1px rgba(@clr-dark--100, 0.25);
                position: relative;
                isolation: isolate;

                &::after {
                    content: '';
                    position: absolute;
                    inset: calc(15px / -2);
                    width: calc(100% + 15px);
                    height: calc(100% + 15px);
                    border-radius: 50px;
                    border: dotted 2px rgba(@clr-dark--100, 0.25);
                    z-index: -1;
                    opacity: 0;
                    transition: opacity 500ms;
                }

                .ant-steps-icon {
                    font-size: 0px;
                    display: inline-block;
                    font-family: "BlockChainIcons" !important;
                    font-weight: 400;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    color: #C1229E;

                    &::before {
                        content: "\e007";
                        font-size: 1.5rem;
                    }
                }
            }

            .ant-steps-item-tail {
                @media screen and (max-width: @max-767) {
                    display: none !important;
                }
            }

            .ant-steps-item-content {
                @media screen and (max-width: @max-767) {
                    display: none;
                }

                .ant-steps-item-title {
                    font-size: 1.25rem;
                    font-weight: 400;
                    color: white;

                    &::after {
                        content: none;
                    }
                }
            }
        }

        &:nth-child(2) {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    .ant-steps-icon::before {
                        content: "\e00e";
                        font-size: 1.75rem;
                    }
                }
            }
        }

        &:nth-child(3) {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    .ant-steps-icon::before {
                        content: "\e012";
                        font-size: 1.75rem;
                    }
                }
            }
        }

        &.ant-steps-item-active {
            .ant-steps-item-container .ant-steps-item-icon {
                background-image: @gradient-400;
                background-size: 200% auto;
                color: white;

                .ant-steps-icon {
                    color: white;
                }

                &:after,
                &::before {
                    opacity: 1;
                }
            }
        }

        &.ant-steps-item-finish {
            .ant-steps-item-container {
                .ant-steps-item-icon {
                    border-color: #C1229E;

                    .ant-steps-icon {
                        color: #C1229E;
                    }
                }
            }
        }
    }
}

.tab_container {
    padding: 2rem;

    @media screen and (max-width: @max-767) {
        padding: 1.5rem;
    }
}


// listing_wrap
.tab_listing {
    .btn_wrap {
        text-align: center;
        margin-top: 1.5rem;
    }
}

.listing_wrap {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        pointer-events: none;
        background-image: linear-gradient(to bottom, transparent, #14141F);
    }

    ul {
        margin: 0;
        padding: 0;
        max-height: 65dvh;
        overflow: auto;
        padding-left: 1rem;
        margin-left: -1rem;
        padding-right: 1rem;
        margin-right: -1rem;

        &::-webkit-scrollbar {
            width: 5px;
            height: 3px;

            @media screen and (max-width: @max-767) {
                width: 3px;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 3%);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background-image: linear-gradient(to bottom, #BD28A0, #7171C5);
            border-radius: 4px;
        }

        li {
            list-style: none;
            display: grid;
            grid-template-columns: 85px 1fr;
            column-gap: 2rem;
            padding: 1rem 2.5rem;
            margin-top: 2rem;
            margin-bottom: 2.5rem;
            background-color: #343444;
            border-radius: 1rem;
            transition: box-shadow 500ms, background-color 500ms;

            @media screen and (max-width: @max-1560) {
                grid-template-columns: 75px 1fr;
                border-radius: 0.875rem;
            }

            @media screen and (max-width: @max-1280) {
                grid-template-columns: 70px 1fr;
                column-gap: 1.5rem;
                border-radius: 0.875rem;
            }

            @media screen and (max-width: @max-991) {
                grid-template-columns: 65px 1fr;
            }

            @media screen and (max-width: @max-767) {
                padding: 1rem;
                column-gap: 1rem;
            }

            .img_wrap {
                border-radius: 1rem;
                margin-top: -2.5rem;
                aspect-ratio: 1;
                overflow: hidden;
                transition: transform 500ms;

                @media screen and (max-width: @max-1280) {
                    margin-top: -2rem;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content {
                font-size: 1.375rem;
                font-weight: 500;
                line-height: 1.2;

                @media screen and (max-width: @max-767) {
                    font-size: 1.125rem;
                }

                p {
                    margin: 0;
                }

                small {
                    font-size: 1.125rem;
                    font-weight: 400;
                    color: @clr-primary--400;

                    @media screen and (max-width: @max-767) {
                        font-size: 1rem;
                    }
                }
            }

            &:hover,
            &:focus {
                cursor: pointer;
                background-color: lighten(#343444, 10px);
                box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.6);
            }
        }
    }
}

// quantity_wrap
.quantity_wrap {
    &::before {
        content: none;
    }

    ul {
        li {
            grid-template-columns: 120px repeat(4, 1fr);
            column-gap: 1.5rem;

            @media screen and (max-width: @max-1560) {
                grid-template-columns: 100px repeat(4, 1fr);
            }

            @media screen and (max-width: @max-1280) {
                grid-template-columns: 80px repeat(4, 1fr);
            }

            @media screen and (max-width: @max-991) {
                grid-template-columns: 80px repeat(4, 1fr);
                row-gap: 1rem;
            }

            .img_wrap {
                margin-top: -2rem;
            }

            .title {
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 1.2;

                @media screen and (max-width: @max-1560) {
                    font-size: 1.4rem;
                }

                @media screen and (max-width: @max-991) {
                    grid-column: span 4;
                }
            }

            .price {
                @media screen and (max-width: @max-991) {
                    grid-column: span 3;
                }

                @media screen and (max-width: @max-767) {
                    grid-column: span 5;
                }
            }

            .available {
                @media screen and (max-width: @max-767) {
                    grid-column: span 4;
                }
            }

            strong {
                color: #FF31CA;
                font-size: 1.25rem;
                font-weight: 500;
                margin-bottom: 0.25em;
                display: block;
            }

            p {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.2;
                margin: 0;
            }
        }
    }
}

// total_price
.total_price {
    border: dotted 1px rgba(@clr-dark--100, 0.25);
    padding: 2rem 2.5rem;
    border-radius: 1rem;
    margin: 2rem 0;

    @media screen and (max-width: @max-767) {
        padding: 1.5rem 2rem;
    }

    p {
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;

        @media screen and (max-width: @max-1560) {
            font-size: 1.4rem;
        }

        @media screen and (max-width: @max-767) {
            font-size: 1.25rem;
        }
    }

    strong {
        color: #FF31CA;
        font-size: 2.125rem;
        font-weight: 700;

        @media screen and (max-width: @max-1560) {
            font-size: 1.875rem;
        }

        @media screen and (max-width: @max-767) {
            font-size: 1.5rem;
        }
    }
}

// counter_wrap
.counter_wrap {
    display: inline-flex;
    --height: 60px;

    @media screen and (max-width: @max-1560) {
        --height: 52px;
    }

    @media screen and (max-width: @max-1280) {
        --height: 48px;
    }

    span,
    button {
        background-color: transparent;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.75rem;
        font-weight: bold;
        padding: 0em;
        margin: 0;
        border: solid 1px rgba(@clr-dark--100, 0.25);
        border-radius: 1rem;
        min-width: var(--height);
        height: var(--height);
    }

    span {
        font-size: 1.25rem;
        font-weight: 500;
        border-radius: 0;
        border-left: none;
        border-right: none;
    }

    button {
        cursor: pointer;

        &:active {
            background-color: rgba(255, 255, 255, 0.1);
        }

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

}

// purchase_wrap
.purchase_wrap {
    padding: 1rem;

    @media screen and (max-width: @max-767) {
        padding: 0rem;
    }

    .col_img {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
        }
    }

    .col_content {
        padding: 2rem;

        @media screen and (max-width: @max-767) {
            padding: 0;
            padding-top: 1.5rem;
        }

        h3 {
            font-size: 2.125rem;
            font-weight: 600;

            @media screen and (max-width: @max-1560) {
                font-size: 2rem;
            }

            @media screen and (max-width: @max-767) {
                font-size: 1.75rem;
            }
        }

        p {
            font-size: 1.5rem;
            font-weight: 400;

            @media screen and (max-width: @max-767) {
                font-size: 1.25rem;
            }
        }

        .btn {
            text-transform: uppercase;
        }
    }

    .qrRow {
        border: dotted 2px rgba(@clr-dark--100, 0.25);
        padding: 1.5rem;
        border-radius: 1rem;
        margin-top: 2rem;
        align-items: center;

        .col_content {
            @media screen and (max-width: @max-767) {
                width: 100%;
                text-align: center;
                padding: 0;
            }

            strong {
                color: #FF31CA;
                font-size: 1.5rem;
                font-weight: 400;

                @media screen and (max-width: @max-1560) {
                    font-size: 1.25rem;
                }
            }

            p {
                font-size: 1.75rem;
                font-weight: 400;

                @media screen and (max-width: @max-1560) {
                    font-size: 1.5rem;
                }
            }
        }

        .col_qr {
            border-left: dotted 2px rgba(@clr-dark--100, 0.25);

            @media screen and (max-width: @max-767) {
                width: 100%;
                border: none;
            }

            img {
                display: block;
                margin: auto;
                max-width: 200px;

                @media screen and (max-width: @max-1560) {
                    max-width: 180px;
                }

                @media screen and (max-width: @max-1280) {
                    max-width: 150px;
                }
            }
        }
    }
}

// addProduct
.addProduct {
    @media screen and (max-width: @max-1560) {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    @media screen and (max-width: @max-767) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    h2 {
        font-size: 2.125rem;
        font-weight: 600;

        @media screen and (max-width: @max-1560) {
            font-size: 2rem;
        }

        @media screen and (max-width: @max-991) {
            font-size: 1.75rem;
        }
    }

    hr {
        margin-bottom: 1rem;

    }

    p {
        color: rgba(@clr-dark--100, 0.65);
    }

    .borderWhite {
        border: solid 1px rgba(@clr-dark--100, 25%);
        border-radius: 0.875rem;
        padding: 2rem 1.5rem;
        margin-bottom: 1.75rem;
    }

    .input_group {
        padding: 0 0.75rem;

        @media screen and (max-width: @max-767) {
            width: 100%;
            padding: 0;
        }

        .ant-form-item {
            margin-bottom: 0;
        }

        .btn_or {
            padding: 0.5em 0.75em;
        }
    }
}

.ant-upload.ant-upload-drag {
    background: transparent;
    border-radius: 8px;

    .ant-upload {
        padding: 1.25rem 0;

        .ant-upload-text {
            color: rgba(255, 255, 255, 0.75);
        }

        .ant-upload-drag-icon {
            margin: 0.75rem 0;

            svg {
                fill: rgba(200, 71, 255, 0.75);
            }
        }
    }
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: rgba(200, 71, 255, 0.75);
}

.ant-upload-list-item {
    color: rgba(255, 255, 255, 0.8);
    // .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    //     color: rgba(255, 255, 255, 0.8);
    // }
    // .ant-upload-list-item-card-actions .anticon{
    //     color: rgba(255, 255, 255, 0.8);
    // }

    &:hover {
        .ant-upload-list-item-info {
            background-color: rgba(255, 255, 255, 5%);
        }
    }
}

.ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
    color: rgba(255, 255, 255, 0.8);
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon>.anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
    color: #ff4d4f;
}

.detailsTab_table {
    padding: 0 !important;
    overflow: hidden;
}

.ant-table-wrapper {
    width: 100%;

    .ant-table {
        background: transparent;

        .ant-table-thead>tr>th {
            background: rgba(255, 255, 255, 0.1);
            border-color: rgba(255, 255, 255, 0.05);
            color: white;
            white-space: nowrap;
            font-size: 1.125rem;
            font-weight: 600;

            &:first-child {
                padding-left: 1.5rem;
            }

            &:last-child {
                padding-right: 1.5rem;
            }
        }

        .ant-table-tbody>tr.ant-table-placeholder:hover>td {
            background-color: inherit !important;
        }

        .ant-table-tbody>tr>td {
            border-color: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.75);
            font-size: 1rem;

            &:first-child {
                padding-left: 1.5rem;
            }

            &:last-child {
                padding-right: 1.5rem;
            }

            .ant-tag {
                background-color: transparent;
                color: inherit;
                border-radius: 5px;
            }
        }

        .ant-table-tbody>tr.ant-table-row:hover>td,
        .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: rgba(255, 255, 255, 0.02);
        }
    }
}

.ant-form-item {
    .ant-picker {
        .ant-picker-input {
            >input {
                color: #FF31CA;
                font-size: 1.125rem;
            }
        }

        .ant-picker-suffix {
            padding-left: 20px;
            position: relative;
            font-size: 20px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                width: 1px;
                height: 36px;
                transform: translateY(-50%);
                background-color: rgba(255, 255, 255, 0.25);
            }

            .anticon {
                svg {
                    width: 24px;

                    path {
                        fill: rgba(255, 255, 255, 0.25);
                    }
                }
            }
        }
    }
}

.auctionModel {
    .ant-modal-body {
        padding: 40px;

        .ant-form {
            .ant-form-item {
                label {
                    font-size: 20px;
                    color: #fff;

                    span {
                        font-size: 16px;
                        color: #fff;
                    }
                }
                .ant-input-number{
                    background-color: #14141F;
                    border: none;
                    width: 100%;
                    max-width: 180px;
                .ant-input-number-input {
                    width: 100%;
                    height: auto;
                    font-size: 1.125rem;
                    font-weight: 500;
                    padding: 0.8em 1em !important;
                    border-radius: 8px;
                    outline: none;
                    resize: none;
                    background-color: rgba(@clr-dark--100, 5%) !important;
                    border: none;
                    color: @input-clr;
                    transition: box-shadow 400ms ease-in-out;

                    &:focus {
                        box-shadow: 0 0 0px 2px rgba(@clr-primary--400, 0.75);

                    }
                }
                }
                .ant-input-number-handler-wrap{
                    background-color: rgba(@clr-dark--100, 5%) ;
                    width: 30px;
                    .ant-input-number-handler{
                        span{
                            svg{
                                width: 20px;
                                height: 20px;
                                path{
                                    fill: #fff;
                                }
                            }
                        }
                        
                        .ant-input-number-handler-up-inner, 
                        .ant-input-number-handler-down-inner{
                            right: 8px;
                            
                        }
                    }
                    .ant-input-number-handler{
                        height: 50% !important;
                        &:hover{
                            height: 50% !important;
                        }
                        }
                }
            }
        }
    }

    .ant-modal-footer {
        border: 1px solid rgba(255, 255, 255, 0.05);
        padding: 20px;
    }
}