* {
    box-sizing: border-box;
    word-break: break-word;

    &::after,
    &::before {
        box-sizing: border-box;
    }

    &::selection {
        background: @clr-primary--400;
        color: @clr-dark--100;
    }
}

html {
    padding: 0px;
    margin: 0px;
    color-scheme: dark;
    scroll-behavior: auto;
    font-size: 16px;

    @media screen and (max-width: @max-1560) {
        font-size: 14px;
    }

    body {
        font-family: @font-family;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        color: @clr-dark--100;
        background-color: @clr-dark--mode;
        margin: 0px;
        padding: 0px;
        width: 100%;
        min-width: 320px;
        overflow-x: hidden;
        touch-action: manipulation;

        &.scroll_off {
            overflow: hidden !important;
        }

        // @at-root {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: @clr-dark--100;
        }

        a {
            display: inline-block;
            color: @clr-primary--400;
            transition: color 500ms;

            &:hover,
            &:focus {
                color: @clr-secondary--400;
            }
        }

        // }
    }
}

.sc_title {
    margin-bottom: 1.75rem;

    h2,
    h3 {
        font-size: 2.375rem;
        font-weight: 600;
        color: @clr-primary--400;
        margin-bottom: 0.2em;

        @media screen and (max-width: @max-1560) {
            font-size: 2.25rem;
        }


        @media screen and (max-width: @max-1280) {
            font-size: 2rem;
        }

        @media screen and (max-width: @max-991) {
            font-size: 1.75rem;
        }

    }

    >p {
        margin-bottom: 1.125rem;
    }
}

.main__page__wrapper {
    &.has__appsidebar {
        .main__page__content {
            display: flex;
            flex-direction: column;
            background-color: @layout-content-background;
            height: 100vh;
        }
    }

    .main__app__content {
        display: flex;
        flex-direction: column;

        .app__container {
            display: flex;
            flex-direction: column;
            padding-left: @gutter;
            padding-right: @gutter;
            padding-bottom: @gutter;

            &__card {
                height: 100%;
            }
        }
    }
}

.ag-grid-wrapper {
    height: 100%;
    min-height: 400px;
}

.logo__wrapper {
    height: @logo-height;
    display: flex;
    align-items: center;

    img {
        max-height: 60px;
        max-width: 100%;

        &+span {
            margin-left: @logo-space;
        }
    }

    span {
        white-space: nowrap;
        font-size: @logo-text-size;
        font-weight: bold;
        color: @text-color;
    }
}

.fullscreen__spinner__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.section {
    padding: @section-padding;
}

.section__big__title {
    display: block;
    font-weight: bold;
    font-size: 56px;
    color: @text-color;
}

.section__subtitle {
    display: block;
    font-size: 24px;
    color: @text-color-secondary;
}

.ant-card {
    .card__title {
        font-weight: 600;
    }
}

.ant-btn,
.btn {
    font-family: @font-family;
    cursor: pointer;
    user-select: none;
    display: inline-flex;
    line-height: normal;
    height: auto;
    column-gap: 0.5em;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    background-image: none;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 0.7em 1.75em;
    border-radius: 2em;
    color: @clr-dark--100;
    background-color: @clr-secondary--500;
    transition: color 500ms,
        background-color 500ms,
        border-color 500ms,
        transform 500ms,
        box-shadow 500ms,
        background-image 500ms,
        background-position 500ms,
        background-size 500ms;
    transition-timing-function: ease-in-out;

    @media screen and (max-width: @max-1560) {
        padding: 0.85em 1.4em;
    }

    @media screen and (max-width: @max-767) {
        padding: 0.75em 1.25em;
    }

    [class*="iconForn-"] {
        font-size: 1.25em;
    }

    &:not(:only-child) {
        margin-bottom: 0.25em;
        margin-right: 0.25em;
    }

    &:active {
        outline: none;
        opacity: 0.925;
    }

    &:hover,
    &:focus {
        background-color: @clr-secondary--400;
    }

    &[disabled],
    &.disabled {
        filter: opacity(0.5);
        cursor: not-allowed;
        pointer-events: none;
    }

    &.icon-right {
        span {
            order: 1;
        }

        [class*="iconFont-"] {
            order: 2;
        }
    }

    &.ant-btn-primary,
    &.btn_primary {
        color: @clr-dark--100;
        background-image: @gradient-400;
        background-size: 200% auto;

        &:hover,
        &:focus {
            background-position: right center;
        }
    }

    &.btn_primary_br {
        color: @clr-dark--100;
        background-image: @gradient-100;
        background-color: transparent;
        background-size: 240% auto;
        box-shadow: inset 0 0 0 1px rgba(@clr-primary--400, 1);
        transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

        &.active,
        &:hover,
        &:focus {
            color: @clr-dark--100;
            background-position: right center;
        }
    }

    &.ant-btn-default,
    &.btn_primary_br_White {
        color: @clr-dark--100;
        background-image: @gradient-100;
        background-color: transparent;
        background-size: 240% auto;
        box-shadow: inset 0 0 0 1px rgba(@clr-dark--100, 0.25);
        transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 200ms 100ms;

        &.active,
        &:hover,
        &:focus {
            color: @clr-dark--100;
            background-position: right center;
            box-shadow: inset 0 0 0 1px rgba(@clr-dark--100, 0);
            transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 400ms 300ms;
        }
    }


    &.btn_secondary {
        background-color: @clr-secondary--400;
        color: @clr-dark--100;

        &:hover,
        &:focus {
            background-color: @clr-secondary--400--hover;
        }
    }

    &.btn_none {
        cursor: unset;

        &:hover,
        &:focus {
            background-position: inherit;
        }
    }

    &.btn_arrow {
        border-radius: 50px;
        padding: 5px;
        width: 40px;
        height: 40px;
        font-size: 140%;
        background-color: @clr-dark--200;
        border: solid 1px @clr-dark--300;
        color: @clr-dark--800;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        transition: border-color 400ms, background-color 400ms;

        // @include size(max-1200) {
        //     width: 35px;
        //     height: 35px;
        // }
        // @include size(max-767) {
        //     width: 32px;
        //     height: 32px;
        // }
        &.left {
            [class*="ico"] {
                transform: scaleX(-1);
            }
        }

        span {
            line-height: 0;
        }

        &:hover,
        &:focus {
            background-color: @clr-secondary--400;
            border-color: @clr-secondary--400;
            color: @clr-dark--100;
        }
    }
}

.bg_img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.light_circle {
    position: relative;
    isolation: isolate;
    overflow: hidden;

    .cr_wrap {
        --size: 300px;
        display: block;
        width: var(--size);
        height: var(--size);
        border-radius: var(--size);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        filter: blur(calc(var(--size) / 2));
        animation: crBlur 8s linear infinite;

        @media screen and (max-width: @max-1280) {
            --size: 250px;
        }

        @media screen and (max-width: @max-991) {
            --size: 200px;
        }

        &.cr_primary {
            background-image: @gradient-400;
            background-size: 200% auto;
        }

        &.cr_secondary {
            --size: 250px;
            left: unset;
            right: 0;
            background-image: none;
            background-color: @clr-secondary--400;
        }

        @keyframes crBlur {

            0%,
            100% {
                transform: scale(1);
                opacity: 0.4;
            }

            50% {
                transform: scale(1.3);
                opacity: 0.8;
            }
        }
    }
}



.bg_blackness {
    position: relative;
    isolation: isolate;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--blackness-clr, #000);
        opacity: var(--blackness, 0.5);
        pointer-events: none;
        z-index: -1;
    }
}

// dropdown ant-design
.ant-space-item .ant-select {
    color: white;
}

.ant-space-item .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: solid 1px rgba(255, 255, 255, 0.25);
    border-radius: 50px;
    font-size: 1.125rem;
    color: white !important;
    height: auto;
    padding: 0.4em 1.5em;
    padding-left: 1.5em;
}

.ant-space-item .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: @clr-primary--400;
}

.ant-space-item .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-space-item .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-inline-end: 12px;
}

.ant-space-item .ant-select-single.ant-select-open .ant-select-selection-item {
    color: white;
}

.ant-space-item .ant-select .ant-select-arrow {
    color: rgba(255, 255, 255, 0.75);
    right: 1.5em;
}

// pagination
.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0 0;
    padding: 0;
    gap: 0.5rem;

    &.ant-table-pagination {
        li {
            min-width: 40px;
            height: 40px;
            padding: 0.35em;
            border-radius: 6px;
            margin: 0;
            border: none;

            a {
                color: white;
            }

            &.ant-pagination-prev,
            &.ant-pagination-next {
                padding: 0.35em;
                margin: 0;

                .ant-pagination-item-link {
                    color: white;
                    background: transparent;
                    border: none;
                    height: auto;
                }
            }
        }
    }

    li {
        list-style: none;
        min-width: 45px;
        height: 45px;
        padding: 0.5em;
        background: transparent;
        border-radius: 2em;

        @media screen and (max-width: @max-767) {
            min-width: 36px;
            height: 36px;
            padding: 0.25em;
            margin-right: 0.3em;
        }

        &.ant-pagination-prev,
        &.ant-pagination-next {
            padding: 0.5em 2em;

            @media screen and (max-width: @max-767) {
                font-size: 0;
                border-radius: 50px;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                svg {
                    font-size: 1rem;
                }
            }
        }

        &:not(.ant-pagination-prev),
        &:not(.ant-pagination-next) {
            background-image: none;
            box-shadow: inset 0 0 0 1px rgba(@clr-dark--100, 0.25);
            transition: background-color 500ms;

            &:hover {
                background-color: rgba(@clr-dark--100, 0.15);
            }
        }

        &.ant-pagination-item-active {
            border: none;
            background-image: @gradient-100;
            color: @clr-dark--100;
            background-position: right center;
            box-shadow: inset 0 0 0 1px rgba(@clr-dark--100, 0);
            transition: background-position 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86), box-shadow 400ms 300ms;
            background-color: transparent;
            background-size: 240% auto;
        }

        a {
            color: @clr-dark--100;

            &:hover,
            &:focus {
                color: @clr-dark--100;
            }
        }

        .ant-pagination-item-link-icon {
            color: white !important;
            opacity: 1 !important;
        }
    }

    li.ant-pagination-options {
        display: none;
    }
}


/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-list {
        position: relative;

        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    .slick-track,
    .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;

        display: block;
        margin-left: auto;
        margin-right: auto;

        &:before,
        &:after {
            display: table;
            content: "";
        }

        &:after {
            clear: both;
        }
    }
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

// form-input
@label-clr: @clr-dark--100;
@input-clr: #FF31CA;

.input_group {
    margin-bottom: 1.25rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }

    &.mb-0 {
        margin-bottom: 0;
    }

    label {
        display: block;
        font-size: 1.125rem;
        font-weight: 500;
        height: auto;
        color: @label-clr;
    }

    .ant-input,
    .input {
        width: 100%;
        height: auto;
        font-size: 1.125rem;
        font-weight: 500;
        padding: 0.8em 1em !important;
        border-radius: 8px;
        outline: none;
        resize: none;
        background-color: rgba(@clr-dark--100, 5%) !important;
        border: none;
        color: @input-clr;
        transition: box-shadow 400ms ease-in-out;

        &:focus {
            box-shadow: 0 0 0px 2px rgba(@clr-primary--400, 0.75);
        }
    }

    .ant-form-item-optional {
        color: rgba(@clr-dark--100, 0.2) !important;
        opacity: 0 !important;
    }

    .input_select {
        .ant-select-selector {
            .input();
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: calc(100% - 0.75em);
            background-size: 0.75em;
            color: @input-clr;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-right: calc(1em + 15px);
        }
    }

    .ant-picker {
        width: 100%;
        height: auto;
        font-size: 1.125rem;
        font-weight: 500;
        padding: 0.8em 1em;
        border-radius: 8px;
        outline: none;
        resize: none;
        background-color: rgba(255, 255, 255, 0.05);
        border: none;
        color: #FF31CA;
        transition: box-shadow 400ms ease-in-out;
    }
}

.ant-form-item-control {
    .ant-form-item-control-input {
        .ant-input-affix-wrapper {
            padding: 0;
            background-color: transparent;
            border: none !important;
            outline: none;

            >input {
                padding-right: 80px !important;
            }

            .ant-input-suffix {
                position: absolute;
                z-index: 9;
                color: #fff;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 17px;
                color: #FF31CA;
                font-weight: 700;
                padding-left: 20px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 1px;
                    height: 36px;
                    transform: translateY(-50%);
                    background-color: rgba(255, 255, 255, 0.25);
                }
            }
        }
    }
}

.ant-select-dropdown {
    background-color: #191921;
    color: white;

    .ant-select-item {
        color: white;
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: #252530;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #20202a;
    }
}

.ant-skeleton-element,
.ant-skeleton-element .ant-skeleton-image.detail_skeleton {
    width: 100%;
    height: 100%;
    line-height: normal;

    .ant-skeleton-image-svg {
        width: 50%;
        height: 50%;
        line-height: normal;
    }
}

.ant-radio-wrapper {
    margin: 0;

    .ant-radio {
        .ant-radio-inner {
            background-color: transparent;
            border: 2px solid #fff;
            width: 30px;
            height: 30px;

            &::after {
                width: 12px;
                height: 12px;
                background-color: @input-clr;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: translateY(-50%);
            }
        }
    }
}

.bid_containet {
    padding: 40px;

    .main-map {
        display: flex;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.05);
        margin-bottom: 20px;
        border-radius: 15px;
        padding: 14px 35px;
        align-items: center;
    }

    @media screen and (max-width: @max-767) {
        padding: 30px;

        .main-map {
            padding: 14px 20px;
        }
    }
}

.ant-modal-footer {
    border-top: 0;
}

.dropDownUser {
    .ant-dropdown-menu {
        border-radius: 20px;
        background: #14141F;
        box-shadow: 0px 4px 39px 0px rgba(255, 255, 255, 0.29);
        padding: 15px 25px;
        position: relative;

        .ant-dropdown-menu-item {
            padding: 0;
            background-color: transparent;

            a {
                padding: 15px;
                color: #D9D9D9;
                font-size: 17px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                width: 100%;
            }

            &:last-child {
                a {
                    border-bottom: none;
                }
            }

            &:hover {
                a {
                    color: @clr-primary--500;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            right: 30px;
            top: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 16px 32px 16px;
            border-color: transparent transparent #14141F transparent;
            display: none;
        }
    }

    @media (max-width: 767px) {
        .ant-dropdown-menu {
            .ant-dropdown-menu-item {
                a {
                    font-size: 16px;
                    padding: 10px;
                }

                .ant-dropdown-menu-item-icon {
                    width: 22px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.auctions {
    position: fixed;
    bottom: 80px;
    left: 20px;

    button {
        width: 70px;
        height: 70px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
}

.ant-popover {
    width: 480px;

    .ant-popover-inner {
        border-radius: 10px;

        .ant-popover-inner-content {
            width: 100%;
            padding: 10px;
        }
    }
}

.auctionItemWrap {
    margin: 0;
    padding: 10px;
    max-height: 400px;
    overflow-X: hidden;
    overflow-Y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 3px;

        @media screen and (max-width: @max-767) {
            width: 3px;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(230, 230, 230, 1);
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #727272;
        border-radius: 4px;
    }

    .auctionItem {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(114, 114, 114, 0.5);

        &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: none;
        }

        .thumb {
            img {
                max-width: 100%;
                border-radius: 15px;
            }
        }

        h2 {
            font-size: 24px;
            color: #727272;
            margin: 0;
            font-weight: 700;
            line-height: 1;
        }

        h5 {
            font-size: 18px;
            font-weight: 600;
            color: #FF31CA;
            margin: 0;
            padding: 0;
        }

        .btnWrap {
            .btn {
                font-size: 12px;
                color: #fff;
                padding: 12px 20px;
                background-image: @gradient-400;
                background-size: 200% auto;

                &:hover,
                &:focus {
                    background-position: right center;
                }
            }
        }

        .countdownWrap {
            .title {
                font-size: 12px;
                color: #727272;
                font-weight: 700;
                margin-bottom: 5px;
            }

            .timer-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                text-align: center;
                margin-bottom: 10px;
                .semicolon {
                    font-size: 36px;
                    font-weight: 700;
                    color: #727272;
                    line-height: 1.1;
                    padding: 0 3px;
                }

                .timer {
                    font-size: 40px;
                    font-weight: 700;
                    color: #727272;
                    line-height: 1;
                    span {
                        display: block;
                        font-size: 12px;
                        font-weight: 500;
                        color: #727272;
                        text-align: center;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}