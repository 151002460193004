.banner {
    background-color: #0D0D11;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .ant-row,
    .row {
        align-items: center;
    }

    .col_content {
        h1 {
            font-size: 4.25rem;
            font-weight: 700;
            color: @clr-dark--100;
            line-height: 1.2;
            margin-bottom: 0.4em;

            @media screen and (max-width: @max-1560) {
                font-size: 3.75rem;
            }

            @media screen and (max-width: @max-1280) {
                font-size: 3.25rem;
            }

            @media screen and (max-width: @max-767) {
                font-size: 2.75rem;
            }

            span {
                display: block;
                font-size: 115%;
                background-image: linear-gradient(to left, #1150B8, #37C7F4, #FF31CA);
                background-clip: text;
                -webkit-text-stroke: 3px transparent;
                color: #0D0D11;
                letter-spacing: -1px;
            }

            img {
                display: block;
                margin-top: 1rem;

                @media screen and (max-width: @max-1280) {
                    max-width: 180px;
                }

                @media screen and (max-width: @max-767) {
                    max-width: 160px;
                }
            }
        }

        p {
            font-size: 1.125rem;
            max-width: 500px;
            line-height: 1.6;
            margin-bottom: 1em;
        }

        .btn_wrap {
            margin-top: 4rem;

            @media screen and (max-width: @max-767) {
                margin-top: 3rem;
            }

            .btn {
                min-width: 160px;
                margin-right: 1.25em;

                @media screen and (max-width: @max-1560) {
                    min-width: 140px;
                }

                @media screen and (max-width: @max-767) {
                    min-width: 130px;
                }
            }
        }

    }

    .col_img {
        @media screen and (max-width: @max-991) {
            display: none;
        }

        .img_orbit {
            --size: 130px;
            --speed: 120s;

            min-height: calc(var(--size) * 5);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            isolation: isolate;

            @media screen and (max-width: @max-1560) {
                --size: 110px;
            }

            @media screen and (max-width: @max-1280) {
                --size: 90px;
            }

            .waveShape {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                z-index: -1;
            }

            .circleShape {
                --size: 160px;
                position: absolute;
                top: 6%;
                left: 12%;
                width: var(--size);
                height: var(--size);
                animation: orbit 60s linear infinite;
                opacity: 1;

                @media screen and (max-width: @max-1560) {
                    --size: 130px;
                }

                @media screen and (max-width: @max-1280) {
                    --size: 100px;
                }
            }

            .bg_shape {

                .shape1,
                .shape2,
                .shape3 {
                    border-radius: 0;
                    position: absolute;
                    animation: orbit2 20s linear infinite;
                }

                .shape1 {
                    top: 44%;
                    left: 4%;

                    @media screen and (max-width: @max-1560) {
                        max-width: 42px;
                    }
                    @media screen and (max-width: @max-1280) {
                        max-width: 36px;
                    }
                }

                .shape2 {
                    bottom: 16%;
                    right: 0;

                    @media screen and (max-width: @max-1560) {
                        bottom: 15%;
                        right: 10%;
                        max-width: 140px;
                    }
                    @media screen and (max-width: @max-1280) {
                        max-width: 110px;
                    }
                }

                .shape3 {
                    top: 35%;
                    right: 18%;
                    max-width: 34px;
                }

                @keyframes orbit2 {

                    0%,
                    100% {
                        transform: rotate(-25deg);
                    }

                    50% {
                        transform: rotate(10deg) translateY(-15px);
                    }
                }
            }

            img {
                max-width: 100%;
                max-height: 100%;
                border-radius: 80px;

                @media screen and (max-width: @max-1560) {
                    border-radius: 60px;
                }
            }

            .center_img {
                width: var(--size);
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 10;
            }

            ul {
                display: grid;
                place-items: center;
                width: var(--size);
                height: calc(var(--size) * 1.54);
                margin: auto;
                position: relative;
                list-style: none;
                transform-origin: center;
                animation: orbit var(--speed) linear infinite;

                // &:hover {
                //     animation-play-state: paused;
                //     --orbit-play-state: paused;
                // }

                li {

                    position: absolute;
                    width: var(--size);
                    aspect-ratio: 1 / 1;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 1;
                    display: grid;
                    place-items: center;

                    &:hover {
                        --throb-play-state: paused;
                    }

                    .foo(6);

                    .foo(@n, @i: 1) when (@i =< @n) {
                        &:nth-child(@{i}) {
                            --throb-delay: (500ms * @i);
                        }

                        .foo(@n, (@i + 1));
                    }


                    >div {
                        width: 100%;
                        height: 100%;
                        display: grid;
                        place-items: center;
                        animation: holdPosition var(--speed) linear infinite;
                        animation-play-state: var(--orbit-play-state, running), var(--throb-play-state, running);

                    }

                    &:nth-child(1) {
                        transform: translate(0, -15rem);

                        @media screen and (max-width: @max-1280) {
                            transform: translate(0, -12rem);
                        }
                    }

                    &:nth-child(2) {
                        transform: translate(12rem, -7.5rem);

                        @media screen and (max-width: @max-1280) {
                            transform: translate(10rem, -6rem);
                        }
                    }

                    &:nth-child(3) {
                        transform: translate(12rem, 7.5rem);

                        @media screen and (max-width: @max-1280) {
                            transform: translate(10rem, 6rem);
                        }
                    }

                    &:nth-child(4) {
                        transform: translate(0rem, 15rem);

                        @media screen and (max-width: @max-1280) {
                            transform: translate(0rem, 12rem);
                        }
                    }

                    &:nth-child(5) {
                        transform: translate(-12rem, 7.5rem);

                        @media screen and (max-width: @max-1280) {
                            transform: translate(-10rem, 6rem);
                        }
                    }

                    &:nth-child(6) {
                        transform: translate(-12rem, -7.5rem);

                        @media screen and (max-width: @max-1280) {
                            transform: translate(-10rem, -6rem);
                        }
                    }
                }

            }


        }

        @keyframes orbit {
            100% {
                rotate: 1turn;
            }
        }

        @keyframes holdPosition {
            100% {
                rotate: -1turn;
            }
        }


    }

    .cr_wrap {
        &.cr_primary {
            --size: 200px;
            top: unset;
            bottom: 20%;
            left: unset;
            right: calc(var(--size) / -2);
            opacity: 0.75;

            @media screen and (max-width: @max-991) {
                --size: 150px;
            }

            @media screen and (max-width: @max-767) {
                --size: 120px;
            }
        }

        &.cr_secondary {
            --size: 200px;
            top: 40%;
            left: calc(var(--size) / -1.25);
            right: unset;

            @media screen and (max-width: @max-991) {
                --size: 150px;
            }

            @media screen and (max-width: @max-767) {
                --size: 120px;
            }

            &.cr2 {
                --size: 250px;
                background-color: #5789DB;
                left: unset;
                top: calc(var(--size) / -2);
                right: 20%;
                opacity: 0.5;

                @media screen and (max-width: @max-1280) {
                    --size: 200px;
                }

                @media screen and (max-width: @max-767) {
                    --size: 150px;
                }
            }
        }
    }

    .small {
        >.cr_wrap {
            --size: 60px;
            filter: none;
            top: 0;
            left: 0;

            @media screen and (max-width: @max-1560) {
                --size: 50px;
            }

            @media screen and (max-width: @max-1280) {
                --size: 40px;
            }

            &.cr_primary {
                background-image: radial-gradient(#B84B81, #932568);
            }

            &.cr_secondary {
                background-image: radial-gradient(#22CDE8, #59A1EF);
            }

            &.cr1 {
                top: unset;
                bottom: 10%;
                left: 50%;

                @media screen and (max-width: @max-1560) {
                    bottom: 8%;
                }

                @media screen and (max-width: @max-991) {
                    left: 60%;
                }

                @media screen and (max-width: @max-767) {
                    bottom: 5%;
                    left: 80%;
                }
            }

            &.cr2 {
                --size: 30px;
                top: 8%;
                left: 3%;

                @media screen and (max-width: @max-1280) {
                    --size: 20px;
                }

                @media screen and (max-width: @max-991) {
                    top: 4%;
                }

                @media screen and (max-width: @max-767) {
                    top: -1%;
                    left: 2%;
                }
            }

            &.cr3 {
                --size: 50px;
                top: 12%;
                left: unset;
                right: 3%;

                @media screen and (max-width: @max-1560) {
                    --size: 40px;
                }

                @media screen and (max-width: @max-1280) {
                    --size: 30px;
                }
            }

            &.cr4 {
                --size: 30px;
                top: unset;
                bottom: 3%;
                left: unset;
                right: 25%;

                @media screen and (max-width: @max-1560) {
                    --size: 20px;
                }

                @media screen and (max-width: @max-1280) {
                    --size: 15px;
                }
            }
        }
    }
}