.footer {
    background-color: #0D0D11;
    color: white;
    padding: 0;
    // background-image: url(../../images/footer-bg.png);
    .content_wrap {
        max-width: 700px;
        margin: auto;
        text-align: center;
        font-size: 1.125rem;
        padding-block: 5rem;
        @media screen and (max-width: @max-991) {
            padding-block: 4rem;
        }
        @media screen and (max-width: @max-767) {
            padding-block: 3rem;
        }
        .logo {
            display: inline-block;
            margin-bottom: 2rem;
            img {
                min-height: 80px;
                margin: auto;
                @media screen and (max-width: @max-1560) {
                    min-height: 70px;
                }
                @media screen and (max-width: @max-991) {
                    min-height: 60px;
                }
                @media screen and (max-width: @max-767) {
                    min-height: 50px;
                }
            }
        }
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.25rem;
            margin-top: 3rem;
            @media screen and (max-width: @max-767) {
                gap: 1rem;
                margin-top: 2rem;
            }
            li {
                list-style: none;
                .btn {
                    font-size: 60px;
                    width: 1em;
                    height: 1em;
                    border-radius: 1em;
                    padding: 0;
                    box-shadow: inset 0 0 0 1px rgba(@clr-dark--100, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    background-size: 250% auto;
                    transition: color 500ms, background-position 500ms;
                    svg {
                        width: 0.4em;
                        transition: transform 500ms;
                    }
                    @media screen and (max-width: @max-1560) {
                        font-size: 50px;
                    }
                    @media screen and (max-width: @max-767) {
                        font-size: 40px;
                    }
                    &:hover,
                    &:focus {
                        background-position: right center;
                    }
                }
            }
        }
    }
    .cpy_wrap {
        border-top: solid 1px rgba(@clr-dark--100, 0.25);
        font-size: 1rem;
        padding: 2.5em;
        text-align: center;
        color:@clr-dark--100;
        @media screen and (max-width: @max-991) {
            padding: 2rem;
        }
        p {
            margin: 0;
        }
    }
}