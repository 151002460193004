.products_section {
    background-color: @clr-dark--mode;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media screen and (max-width: @max-991) {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .cr_wrap {
        &.cr_primary {
            top: 60%;
            left: calc(var(--size) / -1.5);
            opacity: 0.5;
        }

        &.cr_secondary {
            top: 20%;
            right: calc(var(--size) / -1.5);
        }
    }

    .col_link {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 25px;

        .btn {
            margin-right: 1em;
        }

        .linkDropdown {
            >a {
                font-family: @font-family;
                cursor: pointer;
                user-select: none;
                display: inline-flex;
                line-height: normal;
                height: auto;
                column-gap: 0.5em;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                text-decoration: none;
                text-align: center;
                vertical-align: middle;
                background-image: none;
                border: none;
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 1px;
                padding: 0.7em 1.75em;
                border-radius: 2em;
                color: @clr-dark--100;
                box-shadow: inset 0 0 0 1px rgb(255 255 255 / 25%);
                background-color: transparent;
                transition: color 500ms,
                    background-color 500ms,
                    border-color 500ms,
                    transform 500ms,
                    box-shadow 500ms,
                    background-image 500ms,
                    background-position 500ms,
                    background-size 500ms;
                transition-timing-function: ease-in-out;
                &.active,
        &:hover,
        &:focus {
            color: @clr-dark--100;
            background-color: @clr-secondary--500;
        }
            }
        }
    }

    .grid_wrap {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        @media screen and (max-width: @max-767) {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    }

}

.products_list {
    .col_wrap {
        --contTop: 100px;
        position: relative;

        @media screen and (max-width: @max-767) {
            max-width: 380px;
            margin-inline: auto;
        }

        .wei_wrap {
            --left: 1.5rem;
            position: absolute;
            top: 2rem;
            left: 0.29rem;
            z-index: 9;
            color: white;
            font-size: 1.125rem;
            font-weight: 600;
            padding: 0.7em 1.6em;
            padding-bottom: 1.45em;
            padding-left: 1em;
            background-image: @gradient-400;
            background-size: 200% auto;
            background-position: right center;
            // clip-path: polygon(100% 0%, calc(100% - 0.75em) 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
            clip-path: polygon(0 0, 0 calc(100% - 1rem), 1.25rem 100%, 1.25rem calc(100% - 1rem), 100% calc(100% - 1rem), 100% calc(100% - 1rem), 85% 40%, 100% 0, 100% 0);

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: var(--left);
                height: 1rem;
                background-image: @gradient-400;
                background-size: 200% auto;
                filter: brightness(0.8);
            }

            @media screen and (max-width: @max-1560) {
                --left: 1.7rem;
            }
        }

        .img_wrap {
            position: relative;
            border-radius: 1rem;
            overflow: hidden;
            max-width: calc(100% - 3rem);
            margin: auto;
            z-index: 2;

            .ant-image {
                display: block;

                img {
                    width: 100%;
                    height: 320px;
                    object-fit: cover;
                    transition: filter 500ms;
                }

            }

            .btn {
                position: absolute;
                z-index: 9;
                bottom: -4rem;
                left: 50%;
                transform: translateX(-50%);
                transition: bottom 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 100ms, background-position 500ms;
            }
        }




        .content_wrap {
            background-color: #343444;
            padding: 2rem 2.5rem;
            border-radius: 1rem;
            margin-top: calc(var(--contTop) / -1);
            padding-top: calc(var(--contTop) + 2rem);
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 1px;
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                background-image: linear-gradient(to bottom, #BB2AA2, #7171C5);
                border-radius: 1rem;
                z-index: -1;
                pointer-events: none;
                transform-origin: top left;
                transition: transform 500ms 100ms;
            }

            h3,
            a {
                color: #FF31CA;
                font-size: 1.65rem;
                font-weight: 600;
                text-decoration: none;

                span {
                    vertical-align: middle;
                    display: inline-block;
                    font-size: 1rem;
                    padding: 0.2em 0.8em;
                    margin-left: 0.5em;
                    color: white;
                    font-weight: 500;
                    background-image: @gradient-400;
                    background-size: 200% auto;
                    border-radius: 2em;
                }
            }

            a {

                &:hover,
                &:focus {
                    color: @clr-primary--500;
                }
            }

            p {
                font-size: 1.25rem;
                margin-bottom: 0.7em;
            }

            small {
                font-size: 1rem;
            }
        }

        &:hover,
        &:focus {
            .img_wrap {
                img {
                    filter: brightness(0.5);
                }

                .btn {
                    bottom: 25%;
                    transform: translateX(-50%) translateY(50%);
                }
            }

            .content_wrap::before {
                transform: rotate(4deg);
            }
        }
    }
}

.smallInnerBanner {
    background-color: #242431;
    min-height: 300px;
    background-blend-mode: screen;
    background-position: right top;
    background-size: auto;
}

.userInfoSection {
    margin-top: -110px;

    .userInfo {
        .thumb {
            img {
                border-radius: 50%;
                border: 5px solid #14141F;
                box-shadow: 0px 0px 10px 3px rgba(255, 255, 255, 0.40);
            }
        }

        .userDetails {
            margin: 15px 0;
        }

        h3 {
            color: @white;
            font-size: 34px;
            margin: 0;
            font-weight: 600;
        }

        span {
            color: #FF31CA;
            font-size: 18px;
            font-weight: 700;
        }
    }
}


.auctionDropDown{
    .ant-dropdown-menu {
        border-radius: 20px;
        background: #14141F;
        box-shadow: 0px 4px 39px 0px rgba(255, 255, 255, 0.29);
        padding: 0px 15px;
        position: relative;

        .ant-dropdown-menu-item {
            padding: 0;
            background-color: transparent;

            a {
                padding: 15px 0;
                color: #D9D9D9;
                font-size: 17px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.25);
                width: 100%;
            }

            .active {
                color: @clr-primary--500 !important;
            }
            
            &:last-child {
                a {
                    border-bottom: none;
                }
            }

            &:hover {
                a {
                    color: @clr-primary--500;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            right: 30px;
            top: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 16px 32px 16px;
            border-color: transparent transparent #14141F transparent;
            display: none;
        }
    }
}